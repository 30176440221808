import { useHref } from 'hooks'
import modalStyles from 'components/Modal/styles.module.scss'
import textStyles from 'components/Text/styles.module.scss'

const FraudPrevention = () => {
  useHref('fraudprevention')

  return (
    <>
      <h1 className={modalStyles['modal-header']}>FRAUD PREVENTION</h1>
      <p>
        CardCoins is the most reliable method for turning your non-reloadable
        prepaid gift card into Bitcoin. Unfortunately, fraudsters can also
        prefer the combined speed and convenience of our service. It is not
        typically possible to recover funds stolen from a prepaid gift card used
        on CardCoins. Once a fraudster has tricked you into sharing your card
        details they will immediately deplete the balance.
      </p>
      <p>
        Scams come in many shapes and sizes, so it's important to educate
        yourself on the popular methods fraudsters employ when targeting
        innocent victims. Almost all consumer fraud is initiated by a stranger.
        Never send your gift card to someone you don't know. Scammers are
        indiscriminate and may contact you by telephone, SMS, group chat, email,
        social media, postal mail, message board, classifieds website or any
        other mode of online communication.
      </p>
      <p>
        The fraudster will attempt to gain your trust by employing a variety of
        confidence tricks. While the stories may change, the goal is always the
        same: to convince you to purchase them a prepaid gift card with your
        cash. To help you avoid falling prey to fraudsters, we have provided
        below a list of informational resources where you can learn about the
        most common types of scams.
      </p>
      <p data-cy="emailLink">
        <span>
          "If you believe you are a victim of fraud and the perpetrator used
          CardCoins to steal your money, please,{' '}
          <a
            href="/?contact"
            className={`${textStyles['text-bold']} 
            ${textStyles['text-underline']}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </a>
        </span>
      </p>
      <h2 className={modalStyles['modal-sub-header']}>Popular scam types:</h2>
      <ul
        className={`${modalStyles['modal-list']}
        ${textStyles['text-bold']}`}
        data-cy="scamList"
      >
        <li>
          <a href="https://en.wikipedia.org/wiki/Romance_scam">Romance scam</a>
          {/* eslint-disable-next-line max-len */}
          <a href="https://www.consumer.ftc.gov/articles/what-you-need-know-about-romance-scams">
            (FTC)
          </a>
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/IRS_impersonation_scam">
            IRS/Tax extortion
          </a>
          {/* eslint-disable-next-line max-len */}
          <a href="https://www.consumer.ftc.gov/blog/2015/01/another-tax-scam-irs-imposters">
            (FTC)
          </a>
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/Lottery_scam">Lottery scams</a>
          <a href="https://www.consumer.ftc.gov/taxonomy/term/867">(FTC)</a>
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/Phishing">Phishing</a>
          <a href="https://www.consumer.ftc.gov/taxonomy/term/873?page=1">
            (FTC)
          </a>
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/Advance-fee_scam">
            Advance-fee scams
          </a>
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/Technical_support_scam">
            Technical support scams
          </a>
          {/* eslint-disable-next-line max-len */}
          <a href="https://www.consumer.ftc.gov/articles/how-spot-avoid-and-report-tech-support-scams">
            (FTC)
          </a>
        </li>
        <li>
          {/* eslint-disable-next-line max-len */}
          <a href="https://en.wikipedia.org/wiki/List_of_confidence_tricks#Grandparent_scam">
            Scams targeting seniors
          </a>
          {/* eslint-disable-next-line max-len */}
          <a href="https://www.consumer.ftc.gov/blog/2018/10/scams-against-older-adults-reporting-congress">
            (FTC)
          </a>
        </li>
      </ul>
    </>
  )
}

export default FraudPrevention
