import { useHref } from 'hooks'
import modalStyles from 'components/Modal/styles.module.scss'
import textStyles from 'components/Text/styles.module.scss'

function MailTo() {
  return (
    <a
      data-cy="emailLink"
      className={`
        ${textStyles['text-bold']}
        ${textStyles['text-underline']}
      
      `}
      href={`mailto:` + 'support@cardcoins.co'}
    >
      "support@cardcoins.co"
    </a>
  )
}

const TermsOfService = () => {
  useHref('termsofservice')

  return (
    <>
      <div className={modalStyles['modal-header']}>
        <h1>TERMS OF SERVICE</h1>
      </div>
      <h4>1. Acceptance of the Terms of Service</h4>
      <p>
        This is a binding contract between You (<b>"you", "your" or "user"</b> )
        and CardCoins (<b>"CardCoins", "we" or "us"</b>). By using
        www.cardcoins.co, or any associated services or applications provided by
        CardCoins (<b>"CardCoins Website"</b> ), you agree that you have read,
        understood, and accept all of the terms and conditions contained in
        these Terms of Service, as well as in our Privacy Policy, and agree to
        be bound by them.
      </p>
      <p>
        Please read the Terms of Service carefully before you start to use the
        services offered by CardCoins on the CardCoins Website (“CardCoins
        Services”).{' '}
        <b>
          {' '}
          By clicking to agree to the Terms of Service when this option is made
          available to you, you accept and agree to be legally bound and abide
          by these Terms of Service and our Privacy Policy.
        </b>{' '}
        If you do not want to agree to these Terms of Service or the Privacy
        Policy, you must not access or use CardCoins' Services.
      </p>
      <p>
        Changes to the Terms of Service. We may revise and update these Terms of
        Service from time to time in our sole discretion. All changes are
        effective immediately when we post them, and apply to all access to and
        use of the CardCoins Website thereafter. Your continued use of the
        CardCoins Website following the posting of revised Terms of Service
        means that you accept and agree to the changes. You are expected to
        check this page from time to time so you are aware of any changes, as
        they are binding on you.
      </p>
      <h4>2. Basic CardCoins Services</h4>
      <p>
        <strong className={textStyles['text-warning']}>Eligibility</strong>. To
        be eligible to use the CardCoins Services, you must be at least 18 years
        old. The CardCoins Services available to you depends in part upon the
        U.S. State from which you access CardCoins Services. Access from certain
        states may be prohibited. Access from other international locations is
        prohibited. CardCoins Services are only available to users located in
        the United States.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          CardCoins Services
        </strong>
        . The risk of loss in trading or holding virtual currency can be
        substantial. You should therefore carefully consider whether trading or
        holding virtual currency is suitable for you in light of your financial
        condition.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Disclosure of Material Risk
        </strong>
        . Prior to establishing a relationship with CardCoins, and entering into
        a transaction, you should understand the risks associated with
        cryptocurrency:
      </p>
      <ul className={modalStyles['text-warning']}>
        <li>
          Cryptocurrency is not legal tender, is not backed by the United States
          government, and accounts and value balances are not subject to Federal
          Deposit Insurance Corporation (“FDIC”) or Securities Investor
          Protection Corporation (“SIPC”) protections;
        </li>
        <li>
          Legislative and regulatory changes or actions at the state, federal,
          or international level may adversely affect the use, transfer,
          exchange, and value of cryptocurrency;
        </li>
        <li>
          Transactions in cryptocurrency may be irreversible, and, accordingly,
          losses due to fraud, theft of a prepaid card, unauthorized access to a
          user's virtual wallet or accidental transactions may not be
          recoverable;
        </li>
        <li>
          Cryptocurrency transactions shall be deemed to be made when recorded
          on a public ledger, which is not necessarily the date or time that the
          customer initiates the transaction;
        </li>
        <li>
          The value of cryptocurrency may be derived from the continued
          willingness of market participants to exchange fiat currency for
          cryptocurrency, which may result in the potential for permanent and
          total loss of value should the market for cryptocurrency disappear;
        </li>
        <li>
          There is no assurance that a Vendor/Person who accepts cryptocurrency
          as payment today will continue to do so in the future;
        </li>
        <li>
          The volatility and unpredictability of the price of cryptocurrency
          relative to fiat currency may result in significant loss over a short
          period of time;
        </li>
        <li>
          The nature of cryptocurrency may lead to an increased risk of fraud or
          cyber-attack.
        </li>
      </ul>
      <p>
        <strong className={textStyles['text-warning']}>
          Identity Verification and Due Diligence for Individuals
        </strong>
        . In order to use certain features of the CardCoins Services, including
        loading of cryptocurrency from a prepaid card to a virtual wallet
        (“Cryptocurrency Transactions” or “Transactions”) you may be required to
        provide CardCoins with certain personal information, including, but not
        limited to, your full legal name, current residential address, telephone
        number, e-mail address, date of birth, taxpayer identification number,
        social security number, and government identification number. In
        submitting this or any other personal information as may be required,
        you verify that the information is accurate and authentic. You hereby
        authorize CardCoins to, directly or through third parties, make any
        inquiries we consider necessary to verify your identity and/or protect
        against fraud, or the use of CardCoins' Services in connection with
        money laundering, financing of terrorism, sanctions violations or other
        unlawful activity, including to query identity information contained in
        public reports (e.g., your name, address, past addresses, or date of
        birth), and to take action we reasonably deem necessary based on the
        results of such inquiries and reports. You further authorize any and all
        third parties to which such inquiries or requests may be directed to
        fully respond to such inquiries or requests. This includes authorizing
        your wireless operator (AT&amp;T, Sprint, T-Mobile, US Cellular,
        Verizon, or any other branded wireless operator) to use your mobile
        number, name, address, email, network status, customer type, customer
        role, billing type, mobile device identifiers (IMSI and IMEI) and other
        subscriber status details, if available, to allow verification of your
        identity and to compare information you have provided to CardCoins with
        your wireless operator account profile information for the duration of
        the business relationship. See CardCoins' Privacy Policy to learn more
        about how we treat your data and personal information.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Cryptocurrency Transactions
        </strong>
        . CardCoins processes cryptocurrency according to the instructions
        received from its users and we do not guarantee the identity of any
        user, receiver to whose virtual wallet you may send cryptocurrency,
        requestee, or other party. You should verify all transaction information
        prior to submitting instructions to CardCoins. Once submitted to the
        cryptocurrency network, a Cryptocurrency Transaction will be unconfirmed
        for a period of time pending sufficient confirmation of the Transaction
        by the cryptocurrency network, including identification confirmation and
        completion of any due diligence necessary, in the sole determination of
        CardCoins. CardCoins will always display the applicable Conversion Rate
        (as defined in Section 5 below), which includes the Conversion Fee (as
        defined in Section 5 below) at or before the time you authorize the
        transaction. CardCoins reserves the right to delay any Cryptocurrency
        Transaction if it perceives a risk of fraud or illegal activity.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Third Party Payments
        </strong>
        . CardCoins has no control over, or liability for, the delivery,
        quality, safety, legality or any other aspect of the prepaid access card
        or recipient that you may purchase. CardCoins is not responsible for
        ensuring that a seller you are dealing with will actually complete the
        transaction or is authorized to do so. If you experience a problem with
        any prepaid access card purchased from a third party or cryptocurrency
        transferred to a virtual wallet using the CardCoins Services, or if you
        have a dispute with such third party, you must resolve the dispute
        directly with that third party. CardCoins will not resolve such
        disputes.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Operation of the Cryptocurrency Protocol
        </strong>
        . CardCoins does not own or control the underlying software protocol
        which governs the operation of cryptocurrency protocols. In general, the
        underlying protocols are open source and anyone can use, copy, modify,
        and distribute them. By using the CardCoins platform, you acknowledge
        and agree (i) that CardCoins is not responsible for operation of the
        underlying cryptocurrency protocols and that CardCoins makes no
        guarantee of their functionality, security, or availability; and (ii)
        that the underlying cryptocurrency protocols are subject to sudden
        changes in operating rules (i.e. “forks”), and that such forks may
        materially affect the value or function of the cryptocurrency you
        transfer to a virtual wallet by the CardCoins platform. You agree that
        CardCoins may temporarily suspend The Services (with or without advance
        notice to you) and that CardCoins may, in its sole discretion, decide
        whether or not to support (or cease supporting) a cryptocurrency
        protocol entirely. You acknowledge and agree that CardCoins assumes
        absolutely no responsibility whatsoever with respect to an unsupported
        branch of a forked cryptocurrency protocol.
      </p>
      <h4>3. Conversion Services</h4>
      <p>
        <strong className={textStyles['text-warning']}>In General</strong>.
        CardCoins transfers value as determined by the value of U.S. currency
        stored on prepaid access cards to a virtual wallet at the value
        established based on the conversion rate (“Conversion Services”).
        "Conversion Rate" means the price of cryptocurrency in terms of U.S.
        Dollars as quoted on the CardCoins Website. The Conversion Rate is
        stated as a "Buy Price" which is the price in terms of fiat currency or
        virtual currency at which you may buy cryptocurrency from CardCoins. You
        agree, as a condition of using any CardCoins Conversion Services, to
        accept the Conversion Rate as the sole conversion metric. CardCoins
        reserves the right to delay any Conversion Service if it perceives a
        risk of fraud or illegal activity. CardCoins does not guarantee the
        availability of its Conversion Services.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Cryptocurrency Transactions
        </strong>
        . After successfully completing the Verification Procedures (as
        described in Appendix 1) you authorize CardCoins to initiate debits from
        your gift card in settlement of Cryptocurrency Transactions. A
        Conversion Fee (defined below) applies to all Transactions.{' '}
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Conversion Fees</strong>.
        Each Cryptocurrency Transaction is subject to a fee (a "Conversion
        Fee"). The Conversion Fee consists of a fee for the conversion of U.S.
        Dollars to cryptocurrency when you buy cryptocurrency. The applicable
        Conversion Fee is included in the Conversion Rate which is displayed to
        you on the CardCoins Website prior to you completing a Transaction.
        CardCoins reserves the right to adjust the Conversion Fee, but will
        notify you which Conversion Fee applies to your Transaction prior to you
        completing it.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Funds Transfer.</strong>
        CardCoins will dispatch your Cryptocurrency Transaction with a
        corresponding amount of cryptocurrency after value in U.S. Dollars is
        delivered to CardCoins, typically within one hour after the funds are
        received. We will debit your provided prepaid gift card as soon as
        possible on the same day you initiate the transfer.{' '}
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Reversals; Cancellations.
        </strong>{' '}
        You cannot cancel, reverse, or change any Transaction marked as complete
        or pending. If your payment is not successful or if your payment method
        has insufficient funds, you authorize CardCoins, in its sole discretion
        to cancel the Cryptocurrency Transaction. CardCoins reserves the right
        to refuse to process or to cancel any Transaction in its sole
        discretion, even after funds have been debited from your prepaid gift
        card, for any of the following reasons:{' '}
      </p>
      <ul className={modalStyles['text-warning']}>
        <li>
          if CardCoins suspects the Transaction involves (or has a high risk of
          involving) money laundering, terrorist financing, fraud, or any other
          type of illegal activity;
        </li>
        <li>
          in response to a subpoena, court order, or other government order;
        </li>
        <li>
          if CardCoins reasonably suspects that the Transaction is erroneous; or
        </li>
        <li>
          if CardCoins suspects the Transaction relates to a Prohibited Use as
          set forth below.
        </li>
      </ul>
      <p>
        {' '}
        In such instances, CardCoins will refuse to process or will cancel the
        Transaction and is under no obligation to allow you to reinstate a
        Currency Transaction at the same price or on the same terms as the
        cancelled Transaction.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Payment Services Partners.
        </strong>{' '}
        CardCoins uses a third-party financial institution to process any
        payments between you and CardCoins, including payments in relation to
        your use of the Conversion Services.
      </p>
      <h4>4. General Use, Prohibited Use, and Termination</h4>
      <p>
        <strong className={textStyles['text-warning']}>Limited License</strong>.
        We grant you a limited, nonexclusive, nontransferable license, subject
        to the terms of this Agreement, to access and use the "Content") solely
        for approved non-commercial purposes as permitted by CardCoins. Any
        other use of the CardCoins Website or Content is expressly prohibited
        and all other right, title, and interest in the CardCoins Website or
        Content is exclusively the property of CardCoins and its licensors.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Website Accuracy</strong>
        . The information presented on or through the Website is made available
        solely for general information purposes. Although we intend to provide
        accurate and timely information on the CardCoins Website, the CardCoins
        Website (including, without limitation, the Content) may not always be
        entirely accurate, complete or current and may also include technical
        inaccuracies or typographical errors. In an effort to continue to
        provide you with as complete and accurate information as possible,
        information may be changed or updated from time to time without notice,
        including without limitation information regarding our policies,
        products and services. Accordingly, you should verify all information
        before relying on it, and any reliance you place on such information is
        strictly at your own risk. All decisions based on information contained
        on the CardCoins Website are your sole responsibility and we shall have
        no liability for such decisions. Links to third-party materials
        (including without limitation websites) may be provided as a convenience
        but are not controlled by us. You acknowledge and agree that we are not
        responsible for any aspect of the information, content, or services
        contained in any third-party materials or on any third-party sites
        accessible or linked to the CardCoins Website. If you decide to access
        any of the third-party websites linked to the CardCoins Website, you do
        so entirely at your own risk and subject to the terms and conditions of
        use for such websites. We suggest that you review the third-party
        websites' respective Terms of Services and Privacy Policies.{' '}
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Receipts</strong>. In
        connection with your use of the CardCoins Services, CardCoins will
        electronically deliver Cryptocurrency Transaction receipts to you
        through the CardCoins Website at the time of the Transaction, pursuant
        to your acceptance of the Terms and Conditions.{' '}
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Prohibited Use</strong>.
        In connection with your use of the CardCoins Services and interactions
        with third parties, you agree and represent that you will not engage in
        any Prohibited Use (as defined below). We reserve the right at all times
        to monitor, review, retain and/or disclose any information as necessary
        to satisfy any applicable law, regulation, sanctions programs, legal
        process or governmental request. We reserve the right to cancel and/or
        block transactions or freeze funds immediately and without notice if we
        determine, in our sole discretion, that a Transaction is associated with
        a Prohibited Use.
      </p>
      <p>
        {' '}
        You may not use CardCoins to engage in the following categories of
        activity ("Prohibited Uses"). The specific types of use listed below are
        representative, but not exhaustive. By using CardCoins you confirm that
        you will not use the Services to do any of the following:
      </p>
      <ul className={modalStyles['text-warning']}>
        <li>
          Unlawful Activity: Activity which would violate, or assist in
          violation of, any law, statute, ordinance, or regulation, sanctions
          programs administered in the jurisdictions where CardCoins conducts
          business, including but not limited to the U.S. Department of
          Treasury's Office of Foreign Assets Control ("OFAC"), or which would
          involve proceeds of any unlawful activity, including money laundering
          or financing of terrorism, or that would involve publishing,
          distributing or disseminating any unlawful material or information.
        </li>
        <li>
          Abusive Activity: Actions which impose an unreasonable or
          disproportionately large load on our infrastructure, or detrimentally
          interfere with, intercept, or expropriate any system, data, or
          information; transmit or upload any material to the CardCoins Website
          that contains viruses, Trojan horses, worms, or any other harmful or
          deleterious programs; attempt to gain unauthorized access to the
          CardCoins Website, computer systems or networks connected to the
          CardCoins Website, through password mining or any other means; use
          transaction information of another party to access or use the
          CardCoins Website, except in the case of specific Merchants and/or
          applications which are specifically authorized by a user to access
          such user's CardCoins transaction information; or transfer your
          account access or rights to your account to a third-party, unless by
          operation of law or with the express permission of CardCoins.
        </li>
        <li>
          Abuse Other Users: Interfere with another individual's or entity's
          access to or use of any CardCoins Services; defame, abuse, extort,
          harass, stalk, threaten or otherwise violate or infringe the legal
          rights (such as, but not limited to, rights of privacy, publicity and
          intellectual property) of others; incite, threaten, facilitate,
          promote, or encourage hate, racial intolerance, or violent acts
          against others; or harvest or otherwise collect information from the
          CardCoins Website about others.
        </li>
        <li>
          Fraud: Activity which operates to defraud CardCoins, CardCoins users,
          or any other person; provide any false, inaccurate, or misleading
          information to CardCoins.
        </li>
      </ul>
      <p>
        <strong className={textStyles['text-warning']}>
          Transactions Limits
        </strong>
        . The use of CardCoins Services is subject to a limit on the amount of
        volume, stated in U.S. Dollar terms, you may transact or transfer in a
        given period (e.g., daily). The Transaction limits and corresponding due
        diligence requirements are set forth here. Your Transaction limits may
        vary depending on the verification steps you have completed and other
        factors. CardCoins reserves the right to change applicable limits as we
        deem necessary in our sole discretion. If you want to increase your
        Transaction limit, we may require you to submit additional information
        about yourself, provide records, and respond to inquiries from CardCoins
        staff ("Enhanced Verification"). In our sole discretion, we may refuse
        to raise your limits or we may lower your limits at a subsequent time
        even if you have completed Enhanced Verification.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Suspension, Termination, and Cancellation
        </strong>
        . CardCoins may: (a) suspend, restrict, or terminate your access to any
        or all of the CardCoins Services, and/or (b) cancel your CardCoins
        Transaction if:
      </p>
      <ul className={modalStyles['text-warning']}>
        <li>
          We are so required by a facially valid subpoena, court order, or
          binding order of a government authority; or
        </li>
        <li>
          Your CardCoins Transaction is subject to any pending litigation,
          investigation, or government proceeding and/or we perceive a
          heightened risk of legal or regulatory non-compliance associated with
          your CardCoins activity; or
        </li>
        <li>
          We reasonably suspect you of using CardCoins Services in connection
          with a Prohibited Use; or
        </li>
        <li>Our service providers are unable to support your use; or</li>
        <li>
          You take any action that CardCoins deems as circumventing CardCoins's
          verification or other controls.
        </li>
      </ul>
      <p>
        You acknowledge that CardCoins's decision to take certain actions,
        including limiting your access to the CardCoins Services or canceling
        your Transaction, may be based on confidential criteria that are
        essential to CardCoins's risk management and security protocols. You
        agree that CardCoins is under no obligation to disclose the details of
        its risk management and security procedures to you.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Relationship of the Parties
        </strong>
        . CardCoins is an independent contractor for all purposes. Nothing in
        this Agreement shall be deemed or is intended to be deemed, nor shall it
        cause you and CardCoins to be treated as partners, joint venturers, or
        agents for each other.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Security</strong>. You
        are responsible for maintaining adequate security and control of any and
        all order URL links and prepaid gift card numbers. Any loss or
        compromise of the foregoing information may result in unauthorized
        access to your CardCoins Transaction. We assume no responsibility for
        any loss that you may sustain due to compromise of the foregoing
        information due to no fault of CardCoins.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Taxes</strong>. It is
        your sole responsibility to determine whether, and to what extent, any
        taxes apply to any Transactions you conduct through the CardCoins
        Services, and to withhold, collect, report and remit the correct amounts
        of taxes to the appropriate tax authorities.
      </p>
      <h4>5. Customer Feedback, Queries, Complaints and Dispute Resolution</h4>
      <p>
        <strong className={textStyles['text-warning']}>
          Contact CardCoins: If you have any questions, complaints, feedback,
          please contact CardCoins at
        </strong>
        : <MailTo />
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Arbitration; Waiver of Class Action.
        </strong>{' '}
        If you have a dispute with CardCoins, we will attempt to resolve any
        such disputes through our support team. If we cannot resolve the dispute
        through our support team, you and CardCoins agree that any dispute
        arising under this Agreement shall be finally settled in binding
        arbitration, on an individual basis, in accordance with the American
        Arbitration Association's rules for arbitration of consumer-related
        disputes (accessible
        <a
          href="https://www.adr.org/sites/default/files/Consumer%20Rules.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          here
        </a>
        ) and you and CardCoins hereby expressly waive trial by jury and right
        to participate in a class action lawsuit or class-wide arbitration. The
        arbitration will be conducted by a single, neutral arbitrator and shall
        take place in New York City, New York, or another mutually agreeable
        location. The arbitrator may award any relief that a court of competent
        jurisdiction could award, including attorneys' fees when authorized by
        law, and the arbitral decision may be enforced in any court. At your
        request, hearings may be conducted in person or by telephone and the
        arbitrator may provide for submitting and determining motions on briefs,
        without oral hearings. The prevailing party in any action or proceeding
        to enforce this agreement shall be entitled to costs and attorneys'
        fees.
      </p>
      <p>
        Each party will be responsible for any fees or costs, such as attorney
        fees that the party may incur, including filing fees or other
        administrative costs imposed by the arbitrator(s) or arbitration
        administrator. If a court decides that any provision of this section is
        invalid or unenforceable, that provision shall be severed and the other
        parts of this section shall still apply. In any case, the remainder of
        this Agreement will continue to apply.
      </p>
      <h4>6. General Provisions</h4>
      <p>
        <strong className={textStyles['text-warning']}>
          Computer Viruses.
        </strong>{' '}
        We shall not bear any liability, whatsoever, for any damage or
        interruptions caused by any computer viruses, spyware, scareware, Trojan
        horses, worms or other malware that may affect your computer or other
        equipment, or any phishing, spoofing or other attack. We advise the
        regular use of a reputable and readily available virus screening and
        prevention software. You should also be aware that SMS and email
        services are vulnerable to spoofing and phishing attacks and should use
        care in reviewing messages purporting to originate from CardCoins.
        Always use the CardCoins Website to review any transactions or required
        actions if you have any uncertainty regarding the authenticity of any
        communication or notice.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Release of CardCoins; Indemnification
        </strong>
        . You agree to indemnify and hold harmless CardCoins, its affiliates and
        Service Providers, and each of its or their respective officers,
        directors, agents, joint venturers, employees and representatives from
        any claim or demand (including attorneys' fees and any fines, fees or
        penalties imposed by any regulatory authority) arising out of or related
        to your breach of this Agreement or your violation of any law, rule or
        regulation, or the rights of any third party. You release CardCoins, its
        affiliates and service providers, and each of their respective officers,
        directors, agents, joint venturers, employees and representatives from
        any and all claims, demands and damages (actual and consequential) of
        every kind and nature arising out of or in any way connected with such
        disputes.{' '}
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Limitation of Liability; No Warranty
        </strong>
        . TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT SHALL CARDCOINS,
        ITS AFFILIATES AND SERVICE PROVIDERS, OR ANY OF THEIR RESPECTIVE
        OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES OR
        REPRESENTATIVES, BE LIABLE (A) FOR ANY AMOUNT GREATER THAN THE VALUE OF
        THE SUPPORTED VIRTUAL CURRENCY IN YOUR CARDCOINS CRYPTOCURRENCY
        TRANSACTION OR (B) FOR ANY LOST PROFITS OR ANY SPECIAL, INCIDENTAL,
        INDIRECT, INTANGIBLE, PUNITIVE, OR CONSEQUENTIAL DAMAGES, WHETHER BASED
        IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, ARISING
        OUT OF OR IN CONNECTION WITH AUTHORIZED OR UNAUTHORIZED USE OF THE
        CARDCOINS SITE OR THE CARDCOINS SERVICES, OR THIS AGREEMENT, EVEN IF AN
        AUTHORIZED REPRESENTATIVE OF CARDCOINS HAS BEEN ADVISED OF OR KNEW OR
        SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. THIS MEANS, BY WAY
        OF EXAMPLE ONLY (AND WITHOUT LIMITING THE SCOPE OF THE PRECEDING
        SENTENCE), THAT IF YOU CLAIM THAT CARDCOINS FAILED TO PROCESS A
        TRANSACTION PROPERLY, YOUR DAMAGES ARE LIMITED TO NO MORE THAN THE VALUE
        OF THE SUPPORTED VIRTUAL CURRENCY AT ISSUE IN THE TRANSACTION, AND THAT
        YOU MAY NOT RECOVER FOR LOST PROFITS, LOST BUSINESS OPPORTUNITIES, OR
        OTHER TYPES OF SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE, PUNITIVE, OR
        CONSEQUENTIAL DAMAGES IN EXCESS OF THE VALUE OF THE SUPPORTED VIRTUAL
        CURRENCY AT ISSUE IN THE TRANSACTION. SOME JURISDICTIONS DO NOT ALLOW
        THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES SO
        THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
      </p>
      <p>
        THE CARDCOINS SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
        BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED
        OR STATUTORY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
        CARDCOINS SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE,
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR
        NON-INFRINGEMENT. CARDCOINS DOES NOT MAKE ANY REPRESENTATIONS OR
        WARRANTIES THAT ACCESS TO THE SITE, ANY PART OF THE CARDCOINS SERVICES,
        OR ANY OF THE MATERIALS CONTAINED THEREIN, WILL BE CONTINUOUS,
        UNINTERRUPTED, TIMELY, OR ERROR-FREE.
      </p>
      <p>
        CardCoins makes no representations about the accuracy or completeness of
        historical cryptocurrency price data available on the CardCoins Website.
      </p>
      <p>
        IF YOU ARE A NEW JERSEY RESIDENT, the provisions of this Section are
        intended to apply only to the extent permitted under New Jersey law.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Entire Agreement</strong>
        . This Agreement and the Privacy Policy comprise the entire
        understanding and agreement between you and CardCoins as to the subject
        matter hereof, and supersedes any and all prior or other discussions,
        agreements and understandings of any kind (including without limitation
        any prior versions of this Agreement), and every nature between and
        among you and CardCoins. Section headings in this Agreement are for
        convenience only, and shall not govern the meaning or interpretation of
        any provision of this Agreement.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Assignment</strong>. You
        may not assign any rights and/or licenses granted under this Agreement.
        We reserve the right to assign our rights without restriction, including
        without limitation to any CardCoins affiliates or subsidiaries, or to
        any successor in interest of any business associated with the CardCoins
        Services. Any attempted transfer or assignment on your part in violation
        hereof shall be null and void. Subject to the foregoing, this Agreement
        will bind and inure to the benefit of the parties, their successors and
        permitted assigns.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Severability</strong>. If
        any provision of this Agreement shall be determined to be invalid or
        unenforceable under any local, state, federal or government agency rule,
        law or regulation or any governmental agency, local, state, or federal,
        such provision will be changed and interpreted to accomplish the
        objectives of the provision to the greatest extent possible under any
        applicable law and the validity or enforceability of any other provision
        of this Agreement shall not be affected. In any case, the remainder of
        this Agreement will continue to apply.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Change of Control
        </strong>
        . In the event that CardCoins is acquired by or merged with a
        third-party entity, we reserve the right, in any of these circumstances,
        to transfer or assign the information we have collected from you as part
        of such merger, acquisition, sale, or other change of control.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Governing Law</strong>.
        You agree that the laws of the State of New York, without regard to
        principles of conflict of laws, will govern this Agreement and any claim
        or dispute that has arisen or may arise between you and CardCoins,
        except to the extent governed by federal law.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>Force Majeure</strong>.
        We shall not be liable for delays, failure in performance or
        interruption of service which result directly or indirectly from any
        cause or condition beyond our reasonable control, including but not
        limited to, any delay or failure due to any act of God, act of civil or
        military authorities, act of terrorists, civil disturbance, war, strike
        or other labor dispute, fire, interruption in telecommunications or
        Internet services or network provider services, failure of equipment
        and/or software, other catastrophe or any other occurrence which is
        beyond our reasonable control and shall not affect the validity and
        enforceability of any remaining provisions.
      </p>
      <p>
        <strong className={textStyles['text-warning']}>
          Non-Waiver of Rights
        </strong>
        . This agreement shall not be construed to waive rights that cannot be
        waived under applicable state money transmission laws in the state where
        you are located.
      </p>
      <h4>7. VERIFICATION PROCEDURES AND LIMITS</h4>
      <p>
        CardCoins uses multi-level systems and procedures to collect and verify
        information about you in order to protect CardCoins from fraudulent
        users to comply with legal requirements regarding anti-money laundering
        and countering financing of terrorism, comply with U.S. sanctions laws,
        and to prevent use of CardCoin services in connection with other illegal
        activity. Your daily or weekly Transaction threshold limits are based on
        the identifying information and/or proof of identity you provide to
        CardCoins.{' '}
      </p>
      <h4>8. Electronic Delivery of Communications</h4>
      <p>
        You agree and consent to receive electronically all communications,
        agreements, documents, notices and disclosures (collectively,
        "Communications") that we provide in connection with the CardCoins
        Website and your use of CardCoins Services. Communications include:
      </p>
      <ul className={modalStyles['text-warning']}>
        <li>
          Terms of service and policies you agree to (e.g., the CardCoins Terms
          of Service Agreement and Privacy Policy), including updates to these
          agreements or policies;
        </li>
        <li>
          Mobile push notifications, Transaction receipts, confirmations, and
          any other transaction information or statements;
        </li>
        <li>
          Legal, regulatory, and tax disclosures or statements we may be
          required to make available to you; and
        </li>
        <li>
          Responses to claims or customer support inquiries filed in connection
          with your CardCoins Transactions.
        </li>
      </ul>
      <p>
        We will provide these Communications to you by posting them on the
        CardCoins Website, communicating to you via instant chat, and/or through
        other electronic communication such as text message or mobile push
        notification.{' '}
      </p>
      <p>
        Maintaining Current Contact Information It is your responsibility to
        provide us with true, accurate and complete contact information. You
        understand and agree that if CardCoins sends you an electronic
        Communication but you do not receive it because your contact information
        on file is incorrect, out of date, blocked by your service provider, or
        you are otherwise unable to receive electronic Communications, CardCoins
        will be deemed to have provided the Communication to you.{' '}
      </p>
    </>
  )
}

export default TermsOfService
