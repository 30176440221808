import { Image, PresignData } from 'types'
import { prepareURL, prepareOptions } from 'utils'

const getPresignData = async (
  name: string,
  image: Image,
  orderId: string,
  url: string = `/attachments/order/${orderId}`
): Promise<PresignData | Error> => {
  const body = {
    name,
    type: 'image',
    file: {
      name: `${image.name}`,
      size: `${image.size}`
    }
  }

  const res = await fetch(prepareURL(url), prepareOptions(body))

  if (!res.ok) throw res

  return await res.json()
}

export default getPresignData
