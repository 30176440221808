import React, { useContext, useEffect, useState } from 'react'

import { errorHandler, fetchOrder, uploadAllImages } from 'utils'

import {
  determineStep,
  paymentScreenCallback,
  phoneCodeCallback,
  phoneStepCallback
} from './utils'

import {
  CompleteScreen,
  ImagesScreen,
  OnHoldScreen,
  PaymentScreen,
  PhoneScreen,
  ProcessingScreen
} from 'containers'

import FormWrap from 'containers/Layout/Form'

import { Order, Profile } from 'types'

import { StepContext, OrderContext, TickerContext } from 'contexts'

import {
  STEP_PHONE,
  STEP_PHONE_FORM_PHONE_NUMBER,
  STEP_PAYMENT,
  STEP_IMAGES,
  STEP_PROCESSING,
  STEP_ON_HOLD,
  STEP_COMPLETE
} from 'constants/index'

import { LoadingPage } from 'components'

type Props = {
  paramResponse: { order: Order; profile: Profile } | null
}

function isOrder(order: Order | undefined): order is Order {
  return order !== undefined
}

const OrderPage = ({ paramResponse }: Props) => {
  const { step, setStep } = useContext(StepContext)
  const { order, setOrder } = useContext(OrderContext)
  const { ticker } = useContext(TickerContext)
  // @todo narrow typing
  const [profile, setProfile] = useState<Profile>(null)
  const [phone, setPhone] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<boolean | string>(false)
  const [successMsg, setSuccessMsg] = useState<boolean | string>(false)
  const [phonePageStep, setPhonePageStep] = useState<number>(
    STEP_PHONE_FORM_PHONE_NUMBER
  )

  // Keep our order and profile up to date with each step change
  useEffect(() => {
    const fetchOrderAndSetOrder = async (id: string) => {
      setLoading(true)

      const res = await fetchOrder(id)

      if (res instanceof Error) {
        return errorHandler(res)
      }

      const { order, profile } = res
      console.log({ order, profile, step })

      setOrder(order)
      setProfile(profile)
      setLoading(false)
    }

    if (paramResponse) {
      fetchOrderAndSetOrder(paramResponse.order.id)
    } else if (!paramResponse && order) {
      fetchOrderAndSetOrder(order.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  // This logic handles the case where the user either refreshes the page or
  // returns to their unique /orders/:orderID url
  useEffect(() => {
    setErrorMsg(false)
    setLoading(true)

    if (paramResponse) {
      const { order, profile } = paramResponse

      setStep(
        determineStep(order, profile, setOrder, setProfile, setPhonePageStep)
      )
    }

    setLoading(false)
  }, [paramResponse, setOrder, setStep])

  if (!isOrder(order)) {
    return <LoadingPage />
  }

  return (
    <FormWrap step={step}>
      {step === STEP_PHONE && (
        <PhoneScreen
          loading={loading}
          successMsg={successMsg}
          errorMsg={errorMsg}
          phonePageStep={phonePageStep}
          order={order}
          ticker={ticker}
          profile={profile}
          phone={phone}
          onChangePhonePageStep={(v) => setPhonePageStep(v)}
          setSuccessMsg={setSuccessMsg}
          setPhone={setPhone}
          onSetErrorMsg={(msg) => {
            setSuccessMsg(false)
            setErrorMsg(msg)
          }}
          onStepDone={(data) =>
            phoneStepCallback(
              data,
              setLoading,
              order.id,
              setSuccessMsg,
              setPhonePageStep
            )
          }
          onStepCodeDone={async (data) => {
            phoneCodeCallback(
              data,
              setLoading,
              order.id,
              phone,
              setErrorMsg,
              setSuccessMsg,
              setStep
            )
          }}
        />
      )}
      {step === STEP_PAYMENT && (
        <PaymentScreen
          order={order}
          errorMsg={errorMsg}
          successMsg={successMsg}
          loading={loading}
          onSetErrorMsg={(msg) => {
            setSuccessMsg(false)
            setErrorMsg(msg)
          }}
          onStepDone={async (data) => {
            paymentScreenCallback(data, setLoading, order.id, setStep)
          }}
          setSuccessMsg={setSuccessMsg}
          ticker={ticker}
          profile={paramResponse?.profile ?? profile}
        />
      )}
      {step === STEP_IMAGES && (
        <ImagesScreen
          loading={loading}
          successMsg={successMsg}
          errorMsg={errorMsg}
          onSetErrorMsg={(msg) => {
            setSuccessMsg(false)
            setErrorMsg(msg)
          }}
          ticker={ticker}
          onStepDone={async () => {
            setLoading(true)

            const res = await uploadAllImages(order.id)
            if (res.status === 200) {
              setLoading(false)
              setStep(STEP_PROCESSING)
            }
          }}
        />
      )}
      {step === STEP_PROCESSING && (
        <ProcessingScreen
          order={order}
          ticker={ticker}
          onSetErrorMsg={(msg) => {
            setSuccessMsg(false)
            setErrorMsg(msg)
          }}
        />
      )}
      {step === STEP_ON_HOLD && (
        <OnHoldScreen
          order={order}
          ticker={ticker}
          onSetErrorMsg={(msg) => {
            setSuccessMsg(false)
            setErrorMsg(msg)
          }}
        />
      )}
      {step === STEP_COMPLETE && (
        <CompleteScreen order={order} ticker={ticker} />
      )}
    </FormWrap>
  )
}

export default OrderPage
