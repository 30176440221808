import { useHref } from 'hooks'
import modalStyles from 'components/Modal/styles.module.scss'
import textStyles from 'components/Text/styles.module.scss'

const MailTo = () => {
  return (
    <a
      className={`${textStyles['text-bold']} ${textStyles['underline']}`}
      href={`mailto:` + `support@cardcoins.co`}
      data-cy="mailto"
    >
      support@cardcoins.co
    </a>
  )
}

const PrivacyPolicy = () => {
  useHref('privacypolicy')

  return (
    <>
      <h1 className={modalStyles['modal-header']}>PRIVACY POLICY</h1>
      <h4>1. Introduction</h4>
      <p>
        CardCoins (“CardCoins”, Company", "we", “CardCoins” or "us") respects
        your privacy and are committed to protecting it through our compliance
        with this Privacy Policy (“policy”).
      </p>
      <p>
        This policy describes the types of information we may collect from you
        or that you may provide when you visit the CardCoins website
        www.cardcoins.co (the “CardCoins Website”) and use our services
        (“CardCoins Services”) and our practices for collecting, using,
        maintaining, protecting and disclosing that information.
      </p>
      <p>This policy applies to information we collect: </p>
      <ul className={modalStyles['modal-list']}>
        <li>on this CardCoins Website; or</li>
        <li>
          in e-mail, text and other electronic messages between you and this
          CardCoins Website.
        </li>
        <li>It does not apply to information collected by:</li>
        <li>
          us offline or through any other means, including on any other website
          operated by Company or any third party; or
        </li>
        <li>
          any third party, including through any application or content
          (including advertising) that may link to or be accessible from or on
          the CardCoins Website.
        </li>
      </ul>
      <p>
        {' '}
        Please read this policy carefully to understand our policies and
        practices regarding your information and how we will treat it. By
        accessing or using this CardCoins Website or CardCoins Services, you
        agree to this Privacy Policy. This policy may change from time to time.
        If material changes are made to this Privacy Policy, we will revise the
        date on the top of this policy and place a notice that the Privacy
        Policy has been updated on the CardCoins Website home page. Your
        continued use of this CardCoins Website after we make changes is deemed
        to be acceptance of those changes, so please check the policy
        periodically for updates.
      </p>
      <h4>2. Children Under the Age of 18</h4>
      <p>
        {' '}
        The CardCoins Website and CardCoins Services are for all users of at
        least 18 years of age. If you are under 18, do not (i) use the CardCoins
        Website or CardCoins Services;; (ii)register on the CardCoins Website;
        (iii) make any purchases or CardCoins Transactions through the CardCoins
        Website; or (iv) provide any information, including information about
        yourself, like your name, address, telephone number or e-mail address,
        to us through the CardCoins Website or on or through any of its
        features. If we learn we have collected or received personal information
        from anyone under 13 years of age without verification of parental
        consent, we will delete that information. If you believe we might have
        any information from or about anyone under 13 years of age, please
        contact us at <MailTo />.
      </p>
      <h4>3. Information We Collect About You and How We Collect It</h4>
      <p>
        We collect several types of information from and about users of our
        CardCoins Services, including information:{' '}
      </p>
      <ul className={modalStyles['modal-list']}>
        <li>
          by which you may be personally identified, such as name, postal
          address, e-mail address, telephone number, birth date, government
          identification numbers, such as a social security number or a
          state-issued identification number, financial information, such as
          virtual wallet information, and any other identifier by which you may
          be contacted online or offline (“personal information”);
        </li>
        <li>
          that is about you but individually does not identify you, such as
          financial information, transaction information relating to your
          transactions on the CardCoins Website, and information about your
          computer or Internet connection; and
        </li>
        <li>
          the equipment you use to access our CardCoins Website and usage
          details.
        </li>
      </ul>
      <p>We collect this information: </p>
      <ul className={modalStyles['modal-list']}>
        <li>
          directly from you when you provide it to us, such as when you fill in
          forms on or upload information to our CardCoins Website, request
          further services, report a problem with our CardCoins Website or
          CardCoins Services, or send us email correspondences;
        </li>
        <li>
          automatically as you navigate through the CardCoins Website.
          Information collected automatically may include usage details which is
          collected when you visit the CardCoins Website and use the CardCoins
          Services; or
        </li>
        <li>
          from third parties, for example, vendors performing due diligence, or
          data aggregation services.
        </li>
      </ul>
      <h4>
        {/* eslint-disable-next-line max-len */}
        4. Information We Collect through Automatic Data Collection Technologies{' '}
      </h4>
      <p>
        As you navigate through and interact with our CardCoins Website, we may
        use automatic data collection technologies to collect certain
        information about your equipment, browsing actions, and patterns,
        including:{' '}
      </p>
      <ul className={modalStyles['modal-list']}>
        <li>
          details of your visits to our CardCoins Website, including traffic
          data, geolocation, and other communication data and the resources that
          you access and use on the CardCoins Website;
        </li>
        <li>
          information about your computer and internet connection, including
          your IP address, operating system, and browser type; or
        </li>
        <li>
          information about the transactions you complete using CardCoins
          Services, including amount of funds associated with a transaction and
          other transaction information.
        </li>
      </ul>
      <h4>5. Information We Collect From Other Sources</h4>
      <p>
        We may also obtain information from other sources and combine that with
        information we collect through the CardCoins Services. For example, we
        may collect nonpublic personal information about you from third parties,
        including identity verification services.
      </p>
      <h4>6. Third-party Use of Cookies and Other Tracking Technologies</h4>
      <p>
        Some content or applications on the CardCoins Website are served by
        third-party content providers and application providers. These third
        parties may use cookies alone or in conjunction with web beacons or
        other tracking technologies to collect information about you when you
        use our CardCoins Website. The information they collect may be
        associated with your personal information or they may collect
        information, including personal information, about your online
        activities over time and across different websites and other online
        services. They may use this information to provide you with
        interest-based (behavioral) advertising or other targeted content.
      </p>
      <p>
        We do not control these third parties’ tracking technologies or how they
        may be used. If you have any questions about an advertisement or other
        targeted content, you should contact the responsible provider directly.
      </p>
      <h4>7. How We Use Your Information</h4>
      <p>
        We use information that we collect about you or that you provide to us.
        We maintain that information or associate it with personal information
        we collect in other ways or receive from third parties. We use
        information that we collect:{' '}
      </p>
      <ul className={modalStyles['modal-list']}>
        <li>to provide you with CardCoins Services and customer support;</li>
        <li>
          to process cryptocurrency transactions (“Transactions”) and send
          notices about your Transactions;
        </li>
        <li>
          to provide you with notices including technical notices, updates,
          security alerts and support and administrative messages;
        </li>
        <li>
          to carry out our obligations and enforce our rights arising from any
          contracts entered into between you and us, including for billing and
          collection;
        </li>
        <li>
          to prevent potentially prohibited or illegal activities and enforce
          our Terms of Service;
        </li>
        <li>
          to notify you about changes to the CardCoins Website or any products
          or services we offer or provide through it;
        </li>
        <li>
          to allow you to participate in interactive features on our CardCoins
          Website;
        </li>
        <li>to conduct customer verification or due diligence functions;</li>
        <li>to estimate our audience size and usage patterns;</li>
        <li>
          to store information about your preferences, allowing us to customize
          our CardCoins Website according to your individual interests;
        </li>
        <li>to speed up your searches;</li>
        <li>to recognize you when you return to our CardCoins Website;</li>
        <li>
          in any other way we may describe when you provide the information; or
        </li>
        <li>
          for any other purpose with your consent to contact you about our own
          goods and services that may be of interest to you.
        </li>
      </ul>
      <p>
        If you do not want us to use your information in this way, please
        contact us at <MailTo />.
      </p>
      <h4>8. Disclosure of Your Information</h4>
      <p>
        We may disclose aggregated information about our users and information
        that does not identify any individual without restriction. We may
        disclose personal information that we collect or you provide as
        described in this Privacy Policy:{' '}
      </p>
      <ul className={modalStyles['modal-list']}>
        <li>
          to contractors, service providers and other third parties we use to
          support our business and who are bound by contractual obligations to
          keep personal information confidential and use it only for the
          purposes for which we disclose it to them;
        </li>
        <li>
          to a buyer or other successor in the event of a merger, divestiture,
          restructuring, reorganization, dissolution or other sale or transfer
          of some or all of CardCoins’s assets, whether as a going concern or as
          part of bankruptcy, liquidation or similar proceeding, in which
          personal information held by CardCoins about the CardCoins Website
          users is among the assets transferred;
        </li>
        <li>to fulfill the purpose for which you provide it;</li>
        <li>
          for any other purpose disclosed by us when you provide the
          information; or
        </li>
        <li>with your consent.</li>
      </ul>
      <p>We may also disclose your personal information: </p>
      <ul className={modalStyles['modal-list']}>
        <li>
          to comply with any court order, law or regulatory requirement, or
          legal process, including to respond to any government or regulatory
          request. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES,
          LICENSEES, AND SERVICE PROVIDERS, AND ITS AND THEIR AFFILIATES, FROM
          ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE FOREGOING PARTIES
          DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN
          AS A CONSEQUENCE OF INVESTIGATIONS BY SUCH PARTIES OR LAW ENFORCEMENT
          AUTHORITIES;
        </li>
        <li>
          to enforce or apply our Terms of Service and other agreements; or
        </li>
        <li>
          if we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of CardCoins, our customers or others.
          This includes exchanging information with other companies and
          organizations for the purposes of fraud protection and credit risk
          reduction.
        </li>
      </ul>
      <h4>9. Choices About How We Use and Disclose Your Information</h4>
      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with the
        following control over your information:{' '}
      </p>
      <ul className={modalStyles['modal-list']}>
        <li>
          Tracking Technologies and Advertising. You can set your browser to
          refuse all or some browser cookies, or to alert you when cookies are
          being sent. If you disable or refuse cookies, please note that some
          parts of the CardCoins Website may then be inaccessible or not
          function properly.
        </li>
        <li>
          Promotional Offers from the Company. If you do not wish to have your
          email address or other contact information used by the Company to
          promote our own or third parties' products or services, you can
          opt-out by sending us an email stating your request to <MailTo />. If
          we have sent you a promotional email, you may send us a return email
          asking to be omitted from future email distributions.
        </li>
        <li>
          We do not control third parties’ collection or use of your information
          to serve interest-based advertising. However these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way. You can opt out of receiving targeted ads from
          members of the Network Advertising Initiative (“NAI”) on the NAI’s
          website.
        </li>
      </ul>
      <h4>10. Data Security</h4>
      <p>
        We have implemented measures designed to secure your personal
        information from accidental loss and from unauthorized access, use,
        alteration and disclosure. All information you provide to us is stored
        on our secure servers behind firewalls. Any cryptocurrency transactions,
        social security numbers, and virtual wallet information will be
        encrypted using SSL technology.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to our CardCoins Website. Any transmission of
        personal information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the CardCoins Website.
      </p>
      <p>
        The safety and security of your information also depends on you. Do not
        allow other persons to access your virtual wallet, prepaid card
        information, or personal identifying information. Treat the information
        as confidential and do not share it with others.
      </p>
      <h4>11. Your California Privacy Rights</h4>
      <p>
        California Civil Code Section § 1798.83 permits users of the CardCoins
        Website that are California residents to request certain information
        regarding our disclosure of personal information to third parties for
        their direct marketing purposes. To make such a request, please send an
        email to <MailTo />{' '}
      </p>
      <h4>12. Changes to our Privacy Policy</h4>
      <p>
        It is our policy to leave a notice that the privacy policy has been
        updated on the CardCoins Website home page and the top of this page. The
        date the privacy policy was last revised is identified at the top of the
        page. You are responsible for ensuring we have an up-to-date, active and
        deliverable contact method for you, and for periodically visiting our
        CardCoins Website and this privacy policy to check for any changes.
      </p>
      <h4>13. Contact Information</h4>
      <p>
        To ask questions or comment about this Privacy Policy and our privacy
        practices, contact us at: <MailTo />
      </p>
    </>
  )
}

export default PrivacyPolicy
