import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useHref } from 'hooks'
import { FAQ_ITEMS } from 'constants/index'
import { Accordion } from 'components'

import styles from './styles.module.scss'

import { ReactComponent as SearchIcon } from 'assets/images/search.svg'

import modalStyles from 'components/Modal/styles.module.scss'

type ListForSearch = {
  search: string
  firstLevelHash: string
  secondLevelHash: string
}[]

type FAQEntry = {
  questions: {
    [key: string]: {
      index: number
      question: string
      answer: string
    }
  }
  hash: string
  title: string
  index: number
}

type HeaderProps = {
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Header = ({ handleSearch }: HeaderProps) => {
  return (
    <>
      <h1 className={modalStyles['modal-header']}>FAQ</h1>
      <div className={styles['form-search']}>
        <SearchIcon className={styles['icon']} />
        <input
          className={styles['field-input']}
          data-test-id="field-input"
          type="text"
          onChange={handleSearch}
          placeholder="Search for a question"
        />
      </div>
    </>
  )
}

const FAQ = () => {
  let location = useLocation()

  useHref('faq')

  const [search, setSearch] = useState('')
  const [listForSearch, setListForSearch] = useState<ListForSearch>([])
  const [founds, setFound] = useState<Array<string>>([])
  const [hash, setHash] = useState<boolean | string>(false)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setSearch(event.target.value)
  }

  let firstLevel = Object.entries(FAQ_ITEMS)

  useEffect(() => {
    if (location.hash.length > 0) {
      let h = location.hash.replace('#', '')
      setHash(h)
    }

    let list: ListForSearch = []

    firstLevel.forEach((item) => {
      let section: FAQEntry = item[1]

      let firstLevelHash = section.hash

      for (const [key, value] of Object.entries(section.questions)) {
        list.push({
          search: (value.question + ' ' + value.answer).toLowerCase(),
          firstLevelHash,
          secondLevelHash: key
        })
      }
    })

    setListForSearch(list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isSearchMode = search.length > 2

  useEffect(() => {
    let foundHashes: string[] = []

    listForSearch.forEach((item) => {
      let re = new RegExp(search, 'gi')

      let res = item.search.match(re)

      if (res !== null && res.length > 0) {
        foundHashes.push(item.firstLevelHash)
        foundHashes.push(item.secondLevelHash)
      }
    })

    setFound(foundHashes)
    if (search.length > 0) {
      setHash(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  // Extracts the hash that corresponds to the category,
  // later used to open that category
  useEffect(() => {
    if (location.hash.length > 0) {
      let hash = location.hash.replace('#', '')

      setHash(hash)
    }
  }, [location])

  return (
    <>
      <Header handleSearch={handleSearch} />
      {firstLevel &&
        firstLevel.map((firstLevelItem) => {
          let firstLevelHash = ''
          let secondLevel = {}

          let title = ''

          try {
            title = firstLevelItem[1].title
            firstLevelHash = firstLevelItem[1].hash
            secondLevel = firstLevelItem[1].questions
          } catch (error) {
            secondLevel = []
          }

          secondLevel = Object.entries(secondLevel)

          let opened = false

          if (
            hash &&
            typeof hash === 'string' &&
            firstLevelHash === hash.slice(0, 2)
          ) {
            opened = true
          }

          if (isSearchMode) {
            if (founds.includes(firstLevelHash)) {
              opened = true
            } else {
              return null
            }
          }

          return (
            // One Accordion for the top level of the
            // FAQ constant (the categories)...
            <Accordion
              title={title}
              key={firstLevelHash}
              opened={opened}
              hash={firstLevelHash}
            >
              {/* This check may seem odd, but if we don't instantiate 
              secondLevel as an object we get some nasty bugs. 
              Ultimately, secondLevel resolves to an array */}
              {Array.isArray(secondLevel) &&
                secondLevel.map(
                  (
                    secondLevelItem: [
                      string,
                      { index: number; question: string; answer: string }
                    ]
                  ) => {
                    let secondLevelHash = ''

                    let title = ''
                    let content = ''

                    try {
                      secondLevelHash = secondLevelItem[0]
                      title = secondLevelItem[1].question
                      content = secondLevelItem[1].answer
                    } catch (error) {
                      console.warn(error)
                    }

                    // If the hash matches the hash of any of
                    // our categories, open it
                    if (hash && firstLevelHash === hash) {
                      opened = true
                    }

                    if (isSearchMode && !founds.includes(secondLevelHash)) {
                      return null
                    }

                    return (
                      // ...and another Accordion for the second level
                      // (the questions and answers)
                      <Accordion
                        title={title}
                        content={content}
                        key={secondLevelHash}
                        hash={secondLevelHash}
                      />
                    )
                  }
                )}
            </Accordion>
          )
        })}
    </>
  )
}

export default FAQ
