import { HeadersInit } from 'node-fetch'
import { authHeader, prepareOptions, prepareURL } from 'utils'

const createEmailContactOrder = async (
  email: string,
  contact_body: string,
  recaptcha_response?: string
): Promise<{ message: 'success' }> => {
  let headers: HeadersInit = {}

  let auth = authHeader()

  if (auth !== false) {
    headers.Authorization = auth
  }

  const reqBody = {
    email,
    body: contact_body
  }

  if (recaptcha_response && recaptcha_response.length > 0) {
    // @ts-ignore
    reqBody.recaptcha_response = recaptcha_response
  }

  const res = await fetch(prepareURL(`/contact`), prepareOptions(reqBody))

  if (!res.ok) throw res

  return await res.json()
}

export default createEmailContactOrder
