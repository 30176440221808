const errorHandler = (err: any): Promise<any> => {
  const statusCode = err.status

  if (statusCode) {
    return err
      .text()
      .then((res: any) => {
        if (typeof res === 'string') {
          try {
            return JSON.parse(res)
          } catch (error) {
            // @ts-ignore
            return Promise.resolve({ message: error.message, statusCode: 503 })
          }
        }
        return Promise.reject(res)
      })
      .then((res: any) => {
        if (statusCode === 503) {
          res = {
            header: res.message,
            message:
              res.data && res.data.maintenance_message
                ? res.data.maintenance_message
                : ''
          }
        }

        if (typeof res['message'] === 'undefined' || res.message === null) {
          res.message = statusCode + ' STATUS CODE'
        }

        return Promise.resolve({ ...res, statusCode })
      })
      .catch((err: any) => {
        return Promise.reject(err)
      })
  }
  return Promise.resolve(err)
}

export default errorHandler
