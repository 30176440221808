import React, { useEffect } from 'react'
import { ReactComponent as ModalClose } from 'assets/images/modal_close.svg'

import styles from './styles.module.scss'

type Props = {
  show: boolean
  scroll?: boolean
  handleClose: (event: any) => void
  children?: React.ReactNode
}

const Modal = ({ show, scroll = true, handleClose, children }: Props) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        handleClose(event)
      }
    }

    if (show && scroll) {
      document.body.classList.add(styles['modal-open'])
      document.addEventListener('keydown', handleEscape)
    } else {
      document.body.classList.remove(styles['modal-open'])
      document.removeEventListener('keydown', handleEscape)
    }
  }, [show, scroll, handleClose])

  const onClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    document.body.className = ''
    handleClose(event)
  }

  const clickAround = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    // @ts-ignore classList does exist on target
    if (event.target.classList.contains('modal')) {
      event.preventDefault()
      onClose(event as React.MouseEvent<HTMLButtonElement, MouseEvent>)
    }
  }

  return (
    <div
      className={
        `
        ${styles['modal']} 
        ${styles['fade']}` + (show ? ` ${styles['show']}` : ``)
      }
      onClick={clickAround}
      id="modal-container"
    >
      <div className={styles['modal-dialog']}>
        <div className={styles['modal-content']}>
          <button
            className={styles['modal-close']}
            type="button"
            onClick={onClose}
          >
            <ModalClose />
          </button>
          <div
            className={`
              ${styles['modal-body']} 
              ${styles['imageUpload']}
            `}
          >
            {show ? children : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
