import React, { useEffect, useState } from 'react'
import { useQuery, QueryClient, QueryClientProvider } from 'react-query'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Redirect
} from 'react-router-dom'

import { Layout } from 'containers'
import { LoadingPage } from 'components'
import {
  Home,
  LocationRestriction,
  Maintenance,
  OrderPage,
  NotFound,
  ErrorPage
} from 'pages'
import { AppProvider } from 'contexts/AppProvider'
import { StepContext, OrderContext, TickerContext } from 'contexts'
import { fetchOrder } from 'utils'
import { Order, Ticker, Profile, FetchOrderResponse } from 'types'

export default function App() {

  /**
   * Perhaps the logic for the following will be abstracted into separate async
   * functions, and called in succession in a single useEffect hook.
   */


  return (
    <QueryClientProvider client={new QueryClient()}>
      <Router>
        <AppProvider>
          <Layout>
              <Switch>
                <Route component={Maintenance} />
                <Route path="*" render={() => <Redirect to="/" />} />
              </Switch>
          </Layout>
        </AppProvider>
      </Router>
    </QueryClientProvider>
  )
}

/**
 * You are unable to call useParams() outside of a Route component.
 * This wrapper allows us to check for the orderID and fetch the order
 * before Form is rendered, allowing us to handle when the user returns
 * to a URL with an orderID representing an order in flight.
 */

function FormWrapper({
  setMaintenance,
  setLocationRestricted
}: {
  setMaintenance: React.Dispatch<React.SetStateAction<boolean>>
  setLocationRestricted: React.Dispatch<React.SetStateAction<boolean>>
}): React.ReactNode {
  const [errorMsg, setErrorMsg] = useState<boolean | string>(false)
  const { orderID } = useParams<{ orderID: string }>()
  const [paramResponse, setParamResponse] = useState<{
    order: Order
    profile: Profile
  } | null>(null)
  const { data: order, isLoading } = useQuery<FetchOrderResponse | Error>(
    ['order', orderID],
    () => fetchOrder(orderID)
  )

  useEffect(() => {
    if (order instanceof Error) {
      if (order.cause === 403) {
        setLocationRestricted(true)
      } else if (order.cause === 404) {
        setParamResponse(null)
      } else if (order.cause === 503) {
        setMaintenance(true)
      }
      setErrorMsg(order.message)
    }

    if (order && !(order instanceof Error)) {
      setParamResponse(order)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  if (isLoading) {
    return <LoadingPage />
  }

  if (paramResponse) {
    return <OrderPage paramResponse={paramResponse} />
  } else if (errorMsg === 'Order not found') {
    return <NotFound />
  }

  // return <NotFound />
  return <ErrorPage errorMsg={errorMsg as string} />
}

function MapWrapper() {
  return (
    <iframe
      src="./map/index.html"
      title="map"
      style={{
        height: '85vh',
        width: '100vw',
        border: 'none'
      }}
    />
  )
}
