import React, { useContext, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import AppContext from 'contexts/AppProvider'

// import Logo from 'assets/images/logo.png'
import Logo from 'assets/images/logo.svg'
import Menu from 'assets/images/menu.png'
import { ReactComponent as ModalClose } from 'assets/images/modal_close.svg'

import styles from './styles.module.scss'

const HeaderModal = ({ onClose }: { onClose: () => void }) => {
  const { openFAQ } = useContext(AppContext)

  return (
    <div className={styles['modal-container']}>
      <div className={styles['modal-top']}>
        <div className={styles['container']}>
          <img
            className={styles['header-logo-img']}
            src={Logo}
            alt="Cardcoins"
          />

          <button
            className={styles['modal-close']}
            type="button"
            onClick={onClose}
          >
            <ModalClose />
          </button>
        </div>
        <ul>
          <li>
            <NavLink
              className={styles['nav-link']}
              to="/"
              exact
              activeClassName={styles['active']}
              onClick={onClose}
            >
              Home
            </NavLink>
          </li>
          <li>
            <a
              // eslint-disable-next-line max-len
              href="https://blog.cardcoins.co/how-to-buy-bitcoin-with-cardcoins"
              target="_blank"
              rel="noreferrer"
              className={styles['nav-link']}
            >
              How to
            </a>
          </li>
          <li>
            <a
              href="#top"
              target="_self"
              onClick={openFAQ}
              className={styles['nav-link']}
            >
              FAQ
            </a>
          </li>
          <li>
            <NavLink
              to="/map"
              activeClassName={styles['active']}
              className={styles['nav-link']}
              onClick={onClose}
            >
              Locations
            </NavLink>
          </li>
          <li>
            <a
              href="https://blog.cardcoins.co/"
              target="_blank"
              rel="noreferrer"
              className={styles['nav-link']}
            >
              Blog
            </a>
          </li>
        </ul>
      </div>
      <div className={styles['modal-bottom']} onClick={onClose}></div>
    </div>
  )
}

const MobileHeader = () => {
  const [menuExpanded, setMenuExpanded] = useState(false)

  return (
    <header className={styles['header']}>
      {menuExpanded ? (
        <HeaderModal onClose={() => setMenuExpanded(false)} />
      ) : (
        <div className={styles['container']}>
          <Link className={styles['header-logo-img']} to="/">
            <img src={Logo} alt="Cardcoins" />
          </Link>
          <div
            onClick={() =>
              setMenuExpanded((prevMenuExpanded) => !prevMenuExpanded)
            }
          >
            <img className={styles['header-menu']} src={Menu} alt="Menu" />
          </div>
        </div>
      )}
    </header>
  )
}

export default MobileHeader
