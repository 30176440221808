import { HeadersInit } from 'node-fetch'
import authHeader from '../authHeader'

export default function prepareOptions<T>(params: T, method = 'POST') {
  let headers: HeadersInit = {}

  let auth = authHeader()

  if (auth !== false) {
    headers.Authorization = auth
  }

  const options = {
    method,
    headers,
    body: JSON.stringify(params)
  }

  return options
}
