import { useContext } from 'react'
import AppContext from 'contexts/AppProvider'

import styles from './styles.module.scss'

const Footer = () => {
  const {
    openContactUs,
    openLimitsAndCompliance,
    openFraudPrevention,
    openPrivacyPolicy,
    openTermsOfService
  } = useContext(AppContext)

  return (
    <footer className={styles['footer']}>
      <div className={styles['container']}>
        <span className={`${styles['brand']}`}>CardCoins &trade;</span>
        <ul className={styles['footer-menu']}>
          <li>
            <a href="#top" target="_self" onClick={openContactUs}>
              Contact Us
            </a>
          </li>
          <li>
            <a href="#top" target="_self" onClick={openLimitsAndCompliance}>
              Limits/Compliance
            </a>
          </li>
          <li>
            <a href="#top" target="_self" onClick={openFraudPrevention}>
              Fraud Prevention
            </a>
          </li>
          <li>
            <a href="#top" target="_self" onClick={openPrivacyPolicy}>
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="#top" target="_self" onClick={openTermsOfService}>
              Terms of Service
            </a>
          </li>
          <li>
            <a href="https://docs.cardcoins.co/" target="_self">
              Documentation
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
