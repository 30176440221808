import { PresignUpload, Image } from 'types'
import { HeadersInit } from 'node-fetch'

const uploadSingleImage = async (
  presign: PresignUpload,
  fileToUpload: Image
): Promise<XMLDocument | Error> => {
  const formData = new FormData()

  formData.append('acl', presign.fields['acl'])
  formData.append('Content-Type', presign.fields['Content-Type'])
  formData.append(
    'success_action_status',
    presign.fields['success_action_status']
  )
  formData.append('bucket', presign.fields['bucket'])
  formData.append('X-Amz-Algorithm', presign.fields['X-Amz-Algorithm'])
  formData.append('X-Amz-Credential', presign.fields['X-Amz-Credential'])
  formData.append('X-Amz-Date', presign.fields['X-Amz-Date'])
  formData.append('key', presign.fields['key'])
  formData.append('Policy', presign.fields['Policy'])
  formData.append('X-Amz-Signature', presign.fields['X-Amz-Signature'])
  formData.append('file', fileToUpload.file)

  let headers: HeadersInit = {}

  const options = {
    method: 'POST',
    headers,
    body: formData
  }

  try {
    const res = await fetch(
      `https://${presign.fields['bucket']}.s3.amazonaws.com/`,
      options
    )

    const str = await res.text()
    const xml = new window.DOMParser().parseFromString(str, 'text/xml')
    // @ts-ignore
    window.x = xml
    let errorMsg = xml.querySelector('Message')

    if (errorMsg) {
      return Promise.reject({ message: 'AWS: ' + errorMsg.textContent })
    } else {
      return Promise.resolve(xml)
    }
  } catch (err) {
    console.log('s3 err', err)
    return Promise.reject(err)
  }
}

export default uploadSingleImage
