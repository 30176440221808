import React from 'react'
import { ReactComponent as Loading } from 'assets/images/loading.svg'

import buttonStyles from '../styles.module.scss'

type Props = {
  title: string
  disabled: boolean
  loading: boolean
}

const SubmitButton = ({ title, disabled, loading }: Props) => {
  return (
    <>
      {!loading && (
        <button
          className={`
            ${buttonStyles['btn']}
            ${buttonStyles['btn-primary']}
          `}
          type="submit"
          disabled={disabled}
          data-cy="submit-button"
        >
          {title}
        </button>
      )}
      {loading && <Loading width={52} height={52} />}
    </>
  )
}

export default SubmitButton
