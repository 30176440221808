export const STEP_AMOUNT = 1
export const STEP_PHONE = 2
export const STEP_PHONE_CONFIRMATION_CODE = 3
export const STEP_PAYMENT = 4
export const STEP_IMAGES = 5
export const STEP_PROCESSING = 6
export const STEP_ON_HOLD = 7
export const STEP_COMPLETE = 8

export const STEP_PHONE_FORM_PHONE_NUMBER = 1
export const STEP_PHONE_FORM_VERIFICATION_CODE = 2

const renderPath = (): string => {
  return 'rbf-batching-at-cardcoins-diving-into-the-mempool-s-dark-reorg-forest'
}

export const FAQ_ITEMS = {
  GeneralQuestions: {
    questions: {
      'gq-open-loop': {
        index: 0,
        question: 'What is an "open loop" gift card?',
        answer: `A gift card is considered "open loop" or "network branded" 
          when it bears the Visa, Mastercard or American Express logo. The 
          cards are prepaid, non-reloadable and can be used at any retailer
          that participates in these card networks.`
      },
      'gq-cards-accepted': {
        index: 1,
        question: 'What types of gift cards do you accept?',
        answer: `We primarily accept Visa, Mastercard and American Express 
        branded non-reloadable prepaid gift cards purchased in stores.`
      },
      'gq-retailers-accepted': {
        index: 2,
        question: `Do you accept gift cards for specific 
          retailers (e.g. Amazon, iTunes, etc.)?`,
        answer: `No, we only accept Visa, Mastercard and American Express 
          branded non-reloadable prepaid gift cards for now.`
      },
      'gq-reloadable-cards': {
        index: 3,
        question: 'Do you accept reloadable prepaid cards?',
        answer: `Reloadable prepaid cards are not gift cards, but they do have
          non-personalized 'temporary' versions which can be purchased
          in stores. However, these cards usually have onerous registration
          requirements or transaction fees which will be deducted from your
          first purchase, or both. Therefore, if you attempt to authorize
          a CardCoins order for the same amount as your card balance,
          the authorization will fail. These drawbacks make reloadable prepaid
          cards very difficult to use on CardCoins. Issuers of reloadable cards
          make their fees hard to find and understand, but they're usually not
          more than $10. If you're able to reduce the amount of your CardCoins
          order to allow for the reloadable card's transaction fee to be
          deducted, in addition to providing all of the documentation which is
          required for every card, we are usually able to successfully authorize
          and approve these cards on CardCoins. We highly recommend against
          purchasing reloadable prepaid cards, 
          and strongly suggest buying a gift card instead.`
      },
      'gq-rebate-cards': {
        index: 4,
        question: `Do you accept prepaid cards received online or 
          delivered by mail (e.g. via rebate or employee reward programs)?`,
        answer: `There are many different styles and types of gift cards, and 
          here at CardCoins we do our best to accept every one. As long as you
          have all of the materials which came with your card such as its 
          packaging and some form of receipt, we can usually make it work. 
          Unfortunately, sometimes our best may not be good enough. We 
          encourage you to start an order with your card and we promise 
          to try our hardest to accept it!`
      },
      'gq-credit-debit': {
        index: 5,
        question: 'Do you accept credit or debit cards?',
        answer: `No, however some locations may allow you to purchase 
          non-reloadable prepaid gift cards with your credit or debit card. 
          Make sure to be polite and courteous when asking for assistance 
          with prepaid gift cards.`
      },
      'gq-where-to-buy-cards': {
        index: 6,
        question: 'Where can I buy open loop gift cards that you accept?',
        answer: `Most major retailers (e.g. gas stations, supermarkets, 
          pharmacies, convenience stores and big-box stores) have a gift card 
          section where you can find non-reloadable prepaid gift cards. Please 
          use our <a href=\"/map\">map<a> to find a location near you.`
      },
      'gq-acceptable-gift-cards': {
        index: 7,
        question: `How can I make sure I buy a non-reloadable
          prepaid gift card that you accept?`,
        answer: `The best way is to look for the magic word, GIFT on the 
        packaging along with the Visa, MasterCard or American Express branding.`
      },
      'gq-contact': {
        index: 8,
        question: 'How can I contact you?',
        answer: `If you have any questions please use our 
        <a href=\"?contact\">contact form</a>.`
      },
      'gq-sell-crypto': {
        index: 9,
        question: 'Can I sell cryptocurrency to CardCoins?',
        answer: `Not yet! Currently we only sell cryptocurrencies to users, 
          we don't buy them.`
      },
      'gq-buy-gift-cards-with-crypto': {
        index: 10,
        question: 'Can I buy gift cards with cryptocurrency from CardCoins?',
        answer: `Not yet— we're working on it!`
      }
    },
    hash: 'gq',
    title: 'General Questions',
    index: 0
  },
  Fees: {
    questions: {
      'fe-fees': {
        index: 0,
        question: 'What fees does CardCoins charge?',
        answer: `CardCoins's fees are dynamic and included in the cryptocurrency
          prices displayed on the website at all times throughout your order.`
      },
      'fe-price': {
        index: 1,
        question: `Why is the price on CardCoins higher than 
          on other websites like CoinMarketCap?`,
        answer: `CardCoins charges a fee which is added on to the market price. 
          The CardCoins price is visible on our website at all times.`
      },
      'fe-smaller-usd-amount': {
        index: 2,
        question: `My wallet says I received a smaller USD 
          amount of cryptocurrency than the amount I ordered 
          from CardCoins. Where's the rest?`,
        answer: `Sorry for the confusion! You received the USD amount of
            your order with our fees deducted. The exact amount of 
            cryptocurrency you receive is displayed on the website at all 
            times throughout your order.`
      },
      'fe-miner-fee': {
        index: 4,
        question: 'What is a miner fee and who pays for it?',
        answer: `To be included in Bitcoin's permanent historical record, every
          transaction must pay a fee to the network. The amount of the
          fee varies based on bitcoin network conditions and the transaction's
          desired priority. CardCoins currently makes a best-effort
          determination of the network fees for each transaction and pays 
          them on behalf of customers, at no extra charge.`
      }
    },
    hash: 'fe',
    title: 'Fees',
    index: 1
  },
  OrderQuestions: {
    questions: {
      'oq-arrival-estimate': {
        index: 0,
        question: 'How long does it take to receive my cryptocurrency?',
        answer: `If you submit your images correctly you can receive 
        cryptocurrency in as little as 5 minutes! During peak times it could 
        take up to one hour for images to be reviewed.`
      },
      'oq-complete-order-info': {
        index: 1,
        question: 'What information is required to complete an order?',
        answer: `We need a valid US phone number, cryptocurrency address and 
          images of the card, packaging and the receipts you received when 
          the card was purchased and activated. You will be required to
          write handwritten notes on the front and back of the purchase 
          receipt. The first time your volume exceeds $1000 in the calendar 
          week you will be required to provide an image of your government 
          issued ID next to your gift card.`
      },
      'oq-no-login': {
        index: 2,
        question: 'Why is there no login?',
        answer: `CardCoins uses an accountless order model. You will be given 
          a custom URL so you can see the status of your order.`
      },
      'oq-share-order-id': {
        index: 3,
        question: 'Can I share my order ID/URL with other people?',
        answer: `No! Do not share your order ID or order URL with anyone!`
      },
      'oq-max-order': {
        index: 4,
        question: 'How much cryptocurrency can I purchase?',
        answer: `You can purchase between $50-$500 in one order.`
      },
      'oq-multiple-cards': {
        index: 5,
        question: 'Can I charge multiple cards in one order?',
        answer: `No, but you can have multiple orders open at the same time!`
      },
      'oq-phone-number-type': {
        index: 6,
        question: 'What type of phone number can I use for my order?',
        answer: `You need a valid US telephone number that can 
          receive text messages.`
      },
      'oq-no-activation-code': {
        index: 7,
        question: `I did not receive the SMS confirmation code for my order. 
          What should I do?`,
        answer: `Please make sure the number is correct and try again. 
          If you still don't receive it, please use our 
          <a href=\"?contact\">contact form</a>!`
      },
      'oq-invalid-phone-number': {
        index: 8,
        question: `I received the error: “Invalid phone number. Please check the
          number and try again” when starting my order.`,
        answer: `You must have a valid US telephone number that can receive 
          text messages. If you're sure you have one and are still receiving the
          same error message, please use our 
          <a href=\"?contact\">contact form</a> and let us know!`
      },
      'oq-failed-transaction': {
        index: 9,
        question: `I received the error: “The transaction was unsuccessful! 
          Check payment details.”`,
        answer: `Please check your card balance to make sure you have 
          sufficient funds for your order. Non-reloadable prepaid gift 
          cards purchased at certain locations may have 1-2 hour 
          activation delays which are completely out of our control. 
          You should avoid these locations! Some non-reloadable prepaid gift 
          cards require activation by phone or online after purchase. 
          You should avoid these cards! American Express branded gift cards 
          sold at many locations tend to have activation delays. If you're 
          certain your card is active and has sufficient balance for 
          your order, please contact the card issuer.`
      },
      'oq-active-gift-card': {
        index: 10,
        question: `How can I make sure a non-reloadable prepaid 
          gift card will be activated immediately after purchase?`,
        answer: `Great question! Unfortunately this can sometimes be difficult 
          to do. You can start by trying to speak with a manager at the 
          location where the gift cards are sold. You could also try calling 
          the gift card company and asking about the location you're interested
          in. Make sure to be polite and courteous when asking for assistance 
          with prepaid gift cards.`
      },
      'oq-invalid-card': {
        index: 11,
        question: 'I received the error: “Card number invalid.”',
        answer: `Please check your card number carefully and make sure it was 
          typed in correctly. If the issue persists, please use our contact
          form to let us know.`
      },
      'oq-missing-reciept-packaging': {
        index: 12,
        question: `I didn't save my receipt and/or packaging, 
          can I still complete my order?`,
        answer: `Oh no! You must have the gift card receipt and packaging to 
          complete your order with CardCoins. Many locations where prepaid gift
          cards are sold will reprint your purchase and activation receipts for 
          you if you return with your card and politely ask a manager.`
      },
      'oq-writing-utensil': {
        index: 13,
        question: "I don't have a writing utensil, can I complete my order?",
        answer: `No, you must follow the directions to write on your receipt or 
          your order will not be completed.`
      },
      'oq-no-camera': {
        index: 14,
        question: `I don't have a camera to upload images. 
          Can I still complete an order?`,
        answer: `No. Without clearly legible images we will be unable to 
          process your order.`
      },
      'oq-scanner': {
        index: 15,
        question: 'Can I use a scanner to upload my images?',
        answer: 'No! Only high quality color camera photos are accepted.'
      },
      'oq-rejected-images': {
        index: 16,
        question: `I received a text message saying 
          one of my images was rejected! 
          What does it mean?`,
        answer: `We cannot complete your order until you have successfully 
          uploaded the required images. If the images you uploaded are too
          blurry, incomplete or otherwise incorrect, we will give you a 
          detailed reason why in the rejection message. Please read our
<a href=\"https://blog.cardcoins.co/how-to-buy-bitcoin-with-cardcoins\" 
          target="_blank">How To<a>
          for more instructions on the image upload process.`
      },
      'oq-cancel-order': {
        index: 17,
        question: 'Can I cancel my order?',
        answer: `If your order is not yet complete and you would like to 
          cancel your order, please <a href=\"?contact\">contact us</a>. 
          Please note that refunds to non-reloadable prepaid gift cards 
          can take some issuers up to 10 days. Unfortunately this entirely
          out of our control and we cannot speed up the process. Please 
          contact the card issuer for more information.`
      },
      'oq-cancelled-order-refund': {
        index: 18,
        question: `My order was cancelled but my gift 
          card balance has not yet been
          returned. When do I get my money back?`,
        answer: `Some prepaid gift card issuers can hold preauthorizations 
          for up to 10 days. Unfortunately this entirely out of our control 
          and we cannot speed up the process. Please contact the card 
          issuer for more information.`
      },
      'oq-change-order': {
        index: 19,
        question: 'How do I change my order?',
        answer: `All you have to do is start a new order! Your old one will 
          expire and disappear. If you've already authorized a card, please 
          use our <a href=\"?contact\">contact form</a>.`
      }
    },
    hash: 'oq',
    title: 'Order Questions',
    index: 2
  },
  CryptocurrencyQuestions: {
    questions: {
      'cq-crypto-type': {
        index: 0,
        question: "You don't sell the kind of cryptocurrency I want!",
        answer: `Sorry about that! Please <a href=\"/?contact\">let us know</a> 
          which cryptocurrency you would like us to support.`
      },
      'cq-full-coin': {
        index: 1,
        question: 'Do I have to purchase a full coin?',
        answer: `No, you can purchase fractions of a coin within your allowed 
          dollar <a href=\"/?limitscompliance\">limits</a>.`
      },
      'cq-multiple-wallets-currencies': {
        index: 2,
        question: `Can you send my cryptocurrency to multiple addresses and/or 
          multiple cryptocurrencies?`,
        answer: `No, you can only specify a single cryptocurrency and 
          withdrawal address per order. But you can have as many orders as you 
          want all at the same time!`
      },
      'cq-transaction-confirmed': {
        index: 3,
        question: "Why hasn't the transaction you sent me confirmed?",
        answer: `Public cryptocurrency networks do not have guaranteed 
          transaction times. Your transaction may require multiple hours 
          to confirm depending on how busy the network is. We do our best 
          include a network fee that will confirm payout transactions within 
          three hours from the time your order was completed. In the case 
          where your transaction does not confirm in that window, please 
          contact us and request a fee bump. Depending on network conditions 
          we may be able to speed up your transaction, however we cannot 
          guarantee it.`
      },
      'cq-missing-transaction': {
        index: 4,
        question: `CardCoins says my order is complete but I didn't 
          get the transaction!`,
        answer: `You can view your transaction on the public network by 
          clicking the link we provided on your completed order page. 
          Try restarting your wallet if it does not display the incoming 
          transaction.`
      },
      'cq-missing-payout-transaction': {
        index: 5,
        question: `Why is my wallet not displaying the payout transaction after 
          my order is complete?`,
        answer: `You can view your transaction on the public network by 
          clicking the link we provided on your completed order page. 
          Try restarting your wallet if it does not display the 
          incoming transaction.`
      },
      'cq-wrong-address': {
        index: 6,
        question:
          'I sent the cryptocurrency to the wrong address, can I get it back?',
        answer: `Uh oh! Cryptocurrency transactions are 
          <a href=\"https://bitcoin.org/en/you-need-to-know#irreversible\">
          irreversible</a>.`
      },
      'cq-ln-txn-id-chng': {
        index: 7,
        question: `Why is the transaction ID on my order page changing? I 
          keep seeing new transactions showing up in my wallet.`,
        answer: `At CardCoins we use a \"dynamic batching\" system when making
          customer payouts. When your payout is processed, if other CardCoins' 
          payouts are in the mempool, your Bitcoin is added to it. This means 
          that your payout transaction will not only be paying you, but also our
          other customers. If your transaction is the first in a batch, the fee
          estimate will be quite low—and this is on purpose—so that we can
          include other customers in the batch! It also means that if you make 
          multiple orders with CardCoins, they may be paid out in a single 
          transaction, as opposed to multiple. During this batching process, 
          we simultaneously increase the transaction fee. This batching process 
          will continue until the transaction is confirmed on the Bitcoin 
          blockchain. Each new update to the batch cancels out the last one; 
          there is no risk that your funds will be lost in the void. We always 
          make sure that your funds are delivered. If you want to learn more 
          about our batching system, please read this 
          <a href=\"https://blog.cardcoins.co/${renderPath()}\">blog post</a>.`
      },
      'cq-ln-why-use': {
        index: 8,
        question:
          'Why should I use Lightning instead of a normal Bitcoin transaction?',
        answer: `Lightning has many advantages to on-chain transactions. They 
          are instant and low fee, while on-chain transactions can be slow and 
          costly during periods of network congestion. We suggest using 
          Lightning whenever possible!`
      },
      'cq-ln-how-to': {
        index: 9,
        question: 'How do I use Lightning with CardCoins?',
        answer: `Using Lightning at CardCoins is just as easy as buying Bitcoin
          on-chain. When we request for you to provide your wallet information,
          you can supply a Lightning Invoice or 
          <a href=\"https://lightningaddress.com\">Lightning Address</a> 
          instead of a Bitcoin address! It's that easy. Please see 
          <a href=\"/?faq#cq-ln-which-wallet\">here</a> for some 
          suggested Lightning wallets.`
      },
      'cq-ln-lnurl': {
        index: 10,
        question: 'Do you support LNURL?',
        answer: `At this time, we do not directly support LNURL. You can 
          however provide a Lightning Address which utilizes LNURL protocol.`
      },
      'cq-ln-which-wallet': {
        index: 11,
        question: 'Which Bitcoin wallets support Lightning?',
        answer: `Many Bitcoin wallets support Lightning. Each one has its own 
          set of tradeoffs. For novice users who are just getting started, we 
          recommend <a href=\"https://www.walletofsatoshi.com/\">Wallet of 
          Satoshi</a>. Wallet of Satoshi is custodial, so it is important to 
          know that you are trusting them with your money. The benefit is that 
          it is an extremely reliable wallet and you can receive funds while 
          your phone is powered off. It is unlikely you will have any payment 
          failures using this wallet. For more advanced users we recommend 
          <a href=\"https://phoenix.acinq.co/\">Phoenix Wallet</a>. 
          With this wallet, you control your own funds, and have your own 
          Lightning channels. It should be noted that there are some additional 
          fees associated with channel openings which can happen the first time 
          you receive funds over Lightning and if you ever need additional 
          channels. There are chances that payment will fail with this wallet, 
          but it has much lower failure rates than other Lightning wallets.`
      },
      'cq-ln-why-z-amt': {
        index: 12,
        question: `I provided a Lightning invoice with an amount, and it was
          rejected. Why do you only support zero amount Lightning invoices?`,
        answer: `The spot price of your order is not determined until after 
          your Lightning invoice is submitted. Because of this, we have not 
          yet quoted you a specific amount of Bitcoin--if you provide an 
          invoice with an amount, it will likely conflict with your order's 
          quote. For this reason we request you provide zero amount invoices. 
          When your order is complete, we will specify the number of satoshis 
          to be paid. For instructions on how to generate a zero amount 
          invoice, please read 
          <a href=\"/?faq#cq-ln-how-gen\">this FAQ question</a>.`
      },
      'cq-ln-how-gen': {
        index: 13,
        question: 'How do I generate a zero amount Lightning invoice?',
        answer: `Not all Lightning wallets support zero amount invoices. When 
          generating an invoice, if your wallet requires you to supply an 
          amount of satoshis, then it does not support zero amount invoices. 
          <a href=\"/?faq#cq-ln-which-wallet\">Click here</a> to 
          learn about two wallets with zero amount invoice support that we 
          recommend.`
      }
    },
    hash: 'cq',
    title: 'Cryptocurrency Questions',
    index: 3
  },
  Legal: {
    questions: {
      'le-accessible-states': {
        index: 0,
        question: 'What states is CardCoins accessible from?',
        answer: `CardCoins is currently accessible from the following states: 
          AK, AZ, AR, CA, DC, DE, FL, ID, IN, IA, KS, KY, LA, ME, MD, 
          MA, MI, MN, MS, MO, MT, NE, NH, NJ, ND, OH, OK, PA, RI, SC, SD, 
          TN, TX, UT, WV, and WY.`
      },
      'le-outside-us': {
        index: 1,
        question:
          'I am located outside the United States, can I use your services?',
        answer: `Our services are only available to customers in select states 
          within the United States.`
      },
      'le-crypto-min-max': {
        index: 2,
        question: 'How much cryptocurrency can I buy from CardCoins?',
        answer: `You can purchase $50-500 worth of cryptocurrency in a single 
          purchase. For daily and weekly purchasing limits, please see our 
          <a href=\"/?limitscompliance\">limits table</a>.`
      },
      'le-crypto-scam': {
        index: 3,
        question: `I was victim to a scam and I believe the fraudster used your 
          service. What can I do?`,
        answer: `Please read our <a href=\"/?fraudprevention\">fraud 
          prevention</a> page and <a href=\"/?contact\">contact us</a>
          immediately.`
      }
    },
    hash: 'le',
    title: 'Legal',
    index: 4
  }
}
