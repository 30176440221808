import React, { useEffect, useState } from 'react'
import { useHref } from 'hooks'
import { useLocation } from 'react-router-dom'

import { ReactComponent as UploadIcon } from 'assets/images/upload.svg'
import { ReactComponent as IconCancel } from 'assets/images/upload_cancel.svg'
import { ReactComponent as Loading } from 'assets/images/loading.svg'
import { SubmitButton, Alert } from 'components'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  createEmailContactOrder,
  createOrderIDContactOrder,
  errorHandler,
  fetchOrder,
  getPresignData,
  uploadSingleImage
} from 'utils'

import { Image, PresignData } from 'types'

import styles from './styles.module.scss'
import modalStyles from 'components/Modal/styles.module.scss'
import textFieldStyles from 'components/Inputs/TextField/styles.module.scss'
import radioStyles from 'components/Inputs/RadioInput/styles.module.scss'
import fileUploadStyles from 'components/Inputs/FileInput/styles.module.scss'
import formStyles from 'containers/Form/styles.module.scss'

const MAX_IMAGES = 3
const MAX_ALLOWED_IMAGE_SIZE = 5 * 1024 * 1024 // 5MB || 5,242,880 bytes
const MIN_ALLOWED_IMAGE_SIZE = 15 * 1024 // 15KB || 15,360 bytes

type UploadFieldProps = {
  idx: number
  orderId: string
  onError: (msg: string | boolean) => void
  onUploaded: (idx: number, imageData: Image & { key: string }) => void
}

const UploadField = ({
  idx,
  orderId,
  onError,
  onUploaded
}: UploadFieldProps) => {
  const [errorMsg, setErrorUploadField] = useState<boolean | string>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const setErrorMsg = (msg: boolean | string) => {
    setErrorUploadField(msg)
    onError(msg)
  }

  const imageUpload = async (
    image: Image & { key: string },
    orderId: string
  ) => {
    const presignData = await getPresignData(
      'AttachmentImage1',
      image,
      orderId,
      `/orders/${orderId}/contact`
    )

    if (presignData instanceof Error) {
      throw new Error('Failed to upload image')
    }

    const { data }: PresignData = presignData

    if (data) {
      const res: XMLDocument | Error = await uploadSingleImage(data, image)

      if (res instanceof Error) {
        setErrorMsg(res.message)
        throw new Error(res.message)
      }

      if (res) {
        const key = res.getElementsByTagName('Key')[0].childNodes[0].nodeValue

        if (key) {
          image.key = key

          onUploaded(idx, image)
          setLoading(false)
        }
      }
    }
  }

  const onImageChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLInputElement>
  ) => {
    setLoading(false)
    setErrorMsg(false)

    let files: FileList | null = null

    if (event instanceof DragEvent) {
      files = event.dataTransfer?.files ?? null
    } else {
      const inputElement = event.target as HTMLInputElement
      files = inputElement.files
    }

    if (event && files && files.length === 0) {
      setErrorMsg('Image required')
      return
    }

    if (files) {
      let file = files[0]
      let isCorrectType =
        ['image/jpeg', 'image/jpg', 'image/png'].indexOf(file.type) > -1

      if (!isCorrectType) {
        let name = ''
        setErrorMsg(`The file type for image, ${name}, is not allowed.`)
        return
      }

      if (file.size < MIN_ALLOWED_IMAGE_SIZE) {
        let name = ''
        let size = 1024
        setErrorMsg(`The image ${name} is below the ${size}KB minimum`)
        return
      }

      if (file.size > MAX_ALLOWED_IMAGE_SIZE) {
        let name = ''
        let size = 1024
        setErrorMsg(`The image ${name} exceeds the size limit of ${size}MB`)
        return
      }

      const doctypeName = `AttachmentImage${idx + 1}`

      let imageItem = {
        name: file.name,
        size: file.size,
        imageType: doctypeName,
        type: file.type,
        file: file,
        key: ''
      }

      setLoading(true)

      imageUpload(imageItem, orderId)
    }
  }

  const onDragOver = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <label htmlFor="file" className={styles['file-wrapper']}>
      <div
        className={`
      ${textFieldStyles['text-field-container']}
      `}
      >
        <div
          className={`
          ${textFieldStyles['text-field-input']}
          ${fileUploadStyles['file-input-field']}
          ${styles['icon-container-wrapper']}
        `}
        >
          <input
            type="file"
            id="file"
            name="supportingImages"
            onChange={onImageChange}
            onDrop={onImageChange}
            onDragOver={onDragOver}
            accept=".jpg, .jpeg, .png"
            className={styles['file-upload']}
          />
          <div className={styles['icon-container']}>
            {loading && <Loading width={30} height={30} />}
            {!loading && <UploadIcon />}
            <span>Supporting images</span>
          </div>
        </div>
        {errorMsg && (
          <span className={styles['error-wrapper']}>{errorMsg}</span>
        )}
      </div>
    </label>
  )
}

const extractOrderID = (path: string): string | undefined => {
  const orderIdIndex = path.lastIndexOf('/orders/') + '/orders/'.length
  if (orderIdIndex !== -1) {
    return path.substring(orderIdIndex)
  }
}

const ContactUs = () => {
  useHref('contact')
  const location = useLocation()

  const YES = 'Yes'
  const NO = 'No'

  const SUPPORT = 'support'
  const COMPLIANCE = 'compliance'
  const HOLD = 'hold'

  const [formType, setFormType] = useState(SUPPORT)
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [orderId, setOrderId] = useState('')
  const [isUserHaveOrderId, setOrderIdStatus] = useState('')

  const [images, setImages] = useState<Array<Image & { key: string }>>([])

  const [successMsg, setSuccessMsg] = useState<boolean | string>(false)
  const [errorMsg, setErrorMsg] = useState<boolean | string>(false)
  const [loading, setLoading] = useState(false)

  const [recaptchaResponse, setRecaptchaResponse] = useState('')
  const recaptchaRef = React.useRef<ReCAPTCHA>(null)

  useEffect(() => {
    let orderID

    if (location.pathname) {
      orderID = extractOrderID(location.pathname)

      if (orderID) {
        setOrderId(orderID)
      }
    }
  }, [location.pathname])

  const resetCaptcha = () => {
    try {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset()
        setRecaptchaResponse('')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleRecaptcha = (value: string) => {
    setRecaptchaResponse(value)
  }

  const isCompliance = () => formType === COMPLIANCE

  const userHaveOrderId = isUserHaveOrderId === YES
  const userNotHaveOrderId = isUserHaveOrderId === NO

  const messagePlaceholder = () => {
    let placeholder = ''
    switch (formType) {
      case SUPPORT: {
        placeholder = `Do you have a question about 
          the order process? A media or 
          partnership request? Please let us know how we can help you.`
        break
      }
      case COMPLIANCE: {
        placeholder = `This form is for official requests only. 
          Please describe in 
          detail the nature of your request and include any supporting 
          information. We will reply as soon as possible.`
        break
      }
      case HOLD: {
        placeholder = `Is your order on hold? Are you the victim of fraud? 
          Please use this form to contact us immediately.`
        break
      }
      default: {
        placeholder = ''
      }
    }

    return placeholder
      .trim()
      .replace(/\n/g, ' ')
      .replace(/\s{2,}/g, ' ')
  }

  const onChangeFormType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()

    if (event && event.target) {
      setFormType(event.target.value.trim())
    }
  }

  const addImage = (idx: number, imageData: Image & { key: string }) => {
    setImages((prevState) => {
      let copy = prevState.slice()
      copy[idx] = imageData
      return copy.map((i) => i)
    })
  }

  const removeImage = (idx: number) => {
    setImages((prevState) => {
      let copy = prevState.slice()
      copy.splice(idx, 1)
      return copy
    })
  }

  const isReadyToNextStep = () => {
    if (isCompliance()) {
      return (
        email.length > 0 &&
        message.length > 0 &&
        recaptchaResponse &&
        recaptchaResponse.length > 0
      )
    }

    return (
      orderId &&
      orderId.length > 0 &&
      message.length > 0 &&
      images.length &&
      recaptchaResponse &&
      recaptchaResponse.length > 0
    )
  }

  const nextStep = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setErrorMsg(false)
    setSuccessMsg(false)
    setLoading(true)

    let request = null

    if (isCompliance()) {
      request = createEmailContactOrder(email, message)
    } else {
      request = fetchOrder(orderId).then((res) => {
        let keys = images.map((item) => item.key)

        return createOrderIDContactOrder(
          orderId,
          message,
          formType,
          keys,
          recaptchaResponse
        )
      })
    }

    request
      .then(() => {
        setLoading(false)
        setOrderIdStatus('')
        setEmail('')
        setMessage('')
        setOrderId('')
        setSuccessMsg('Your message has been received!')
        resetCaptcha()
      })
      .catch((error) => {
        resetCaptcha()
        return errorHandler(error).then((err) => {
          setLoading(false)

          if (
            err.statusCode === 400 &&
            err.message &&
            err.message === 'Failed to load order'
          ) {
            setErrorMsg(err.message)
          } else if (err.statusCode === 403) {
            setErrorMsg(err.message)
          } else if (err.statusCode === 404) {
            setErrorMsg(err.message)
          } else if (err.message) {
            setErrorMsg(err.message)
          }

          return err
        })
      })
  }

  return (
    <>
      <div className={modalStyles['modal-header']}>
        <h1>CONTACT US</h1>
      </div>
      <form action="#" method="post" onSubmit={(event) => nextStep(event)}>
        <div className={formStyles['details-row']}>
          <div className={formStyles['details-col']}>
            <div className={textFieldStyles['text-field-container']}>
              <label
                className={textFieldStyles['text-field-label']}
                htmlFor="how-we-help"
              >
                How can we help?
              </label>
              <select
                className={textFieldStyles['text-field-input']}
                id="how-we-help"
                onChange={onChangeFormType}
              >
                <option value={SUPPORT}>Support</option>
                <option value={COMPLIANCE}>Compliance</option>
                <option value={HOLD}>Hold / Fraud</option>
              </select>
            </div>
          </div>
          <div className={formStyles['details-col']}>
            <div className={styles['text-field-container']}>
              <label
                className={textFieldStyles['text-field-label']}
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                className={textFieldStyles['text-field-input']}
                rows={5}
                cols={10}
                id="message"
                onChange={(event) => setMessage(event.target.value)}
                placeholder={messagePlaceholder()}
              />
            </div>
          </div>
          {isCompliance() && (
            <div className={formStyles['details-col']}>
              <div className={textFieldStyles['text-field-container']}>
                <label
                  className={textFieldStyles['text-field-label']}
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  className={textFieldStyles['text-field-input']}
                  id="email"
                  type="email"
                  name="email"
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Email Address"
                />
              </div>
            </div>
          )}
          {!isCompliance() && (
            <div className={formStyles['details-col']}>
              <div className={textFieldStyles['text-field-container']}>
                <label className={textFieldStyles['text-field-label']}>
                  Do you have an Order ID?
                </label>
                <div className={textFieldStyles['text-field-container']}>
                  <div
                    className={`
                    ${radioStyles['radio-field']}
                    ${radioStyles['radio-field-button']} 
                    ${radioStyles['radio-field-inline']}`}
                  >
                    <input
                      type="radio"
                      id="orderIdYes"
                      name="orderId"
                      value={YES}
                      checked={userHaveOrderId}
                      onChange={() => setOrderIdStatus(YES)}
                      className={radioStyles['radio-field-input']}
                    />
                    <label
                      className={radioStyles['radio-field-label']}
                      htmlFor="orderIdYes"
                    >
                      {YES}
                    </label>
                  </div>
                  <div
                    className={`
                    ${radioStyles['radio-field']}
                    ${radioStyles['radio-field-button']}
                    ${radioStyles['radio-field-inline']}
                    `}
                  >
                    <input
                      type="radio"
                      id="orderIdNo"
                      name="orderId"
                      value={NO}
                      checked={userNotHaveOrderId}
                      onChange={() => setOrderIdStatus(NO)}
                      className={radioStyles['radio-field-input']}
                    />
                    <label
                      className={radioStyles['radio-field-label']}
                      htmlFor="orderIdNo"
                    >
                      {NO}
                    </label>
                  </div>
                </div>
              </div>
              {userHaveOrderId && (
                <div className={styles['order-id-container']}>
                  <div className={textFieldStyles['text-field-container']}>
                    <input
                      className={textFieldStyles['text-field-input']}
                      value={orderId}
                      onChange={(event) => setOrderId(event.target.value)}
                      name="orderId"
                      placeholder="Type in your Order ID"
                    />
                  </div>
                  {images.length < MAX_IMAGES && (
                    <UploadField
                      idx={images.length}
                      orderId={orderId}
                      onError={(msg) => setErrorMsg(msg)}
                      onUploaded={(idx, imageData) => addImage(idx, imageData)}
                    />
                  )}
                  {images &&
                    images.map((item, idx) => {
                      return (
                        <div
                          className={textFieldStyles['text-field-container']}
                          key={idx}
                        >
                          <div
                            className={`
                            ${textFieldStyles['text-field-input']} 
                            ${fileUploadStyles['file-input-field']}
                            ${modalStyles['valid']}
                          `}
                          >
                            <span>{item.name}</span>
                            <button
                              type="button"
                              onClick={() => removeImage(idx)}
                              className={modalStyles['modal-close']}
                            >
                              <IconCancel />
                            </button>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              {userNotHaveOrderId && (
                <p>
                  If you don't have an Order ID and would like to contact us,
                  please <a href="mailto:support@cardcoins.co">email us</a> or
                  send an <a href="sms:1-307-655-7497">SMS</a> to our support
                  line.
                </p>
              )}
            </div>
          )}
          <div className={formStyles['details-col']}>
            <div className={textFieldStyles['text-field-container']}>
              {/* @ts-ignore */}
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                ref={recaptchaRef}
                onChange={(value: string) => handleRecaptcha(value)}
                onExpired={(value: string) => handleRecaptcha(value)}
                onErrored={(value: string) => handleRecaptcha(value)}
                data-test-id="recaptcha"
              />
            </div>
          </div>
        </div>
        <div className={formStyles['form-action']}>
          <Alert errorMsg={errorMsg} successMsg={successMsg} />
        </div>
        <div className={formStyles['form-action']}>
          <SubmitButton
            title="Submit"
            disabled={!isReadyToNextStep()}
            loading={loading}
          />
        </div>
      </form>
    </>
  )
}

export default ContactUs
