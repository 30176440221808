import styles from './styles.module.scss'

type AlertProps = {
  successMsg: string | boolean
  errorMsg: string | boolean
}

const Alert = ({ successMsg, errorMsg }: AlertProps) => {
  if (successMsg !== false) {
    return (
      <div className={styles['alert-success']} data-cy="alert">
        {successMsg}
      </div>
    )
  }

  if (errorMsg !== false) {
    return (
      <div className={styles['alert']} data-cy="alert">
        {errorMsg}
      </div>
    )
  }

  return null
}

export default Alert
