export default function authHeader(): string | false {
  const auth = window.localStorage.getItem('auth')

  if (auth !== null) {
    const parsedAuth = JSON.parse(auth)
    if (parsedAuth && parsedAuth.u && parsedAuth.p) {
      return `Basic ` + btoa(parsedAuth.u + ':' + parsedAuth.p)
    }
  }

  return false
}
