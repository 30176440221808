import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

interface Props {
  title: string
  hash: string
  content?: string
  opened?: boolean
  children?: React.ReactNode
}

import styles from './styles.module.scss'

const Accordion = ({ title, content, opened, children, hash }: Props) => {
  const [isOpen, setStatus] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setStatus(!isOpen)
    opened = !isOpen

    // Append the hash to the URL
    if (opened) {
      history.push(`?faq#${hash}`)
    } else if (hash.length > 2) {
      history.push(`?faq#${hash.slice(0, 2)}`)
    } else if (!opened) {
      history.push(`?faq`)
    }
  }

  const isHashMatching = () => {
    return location.hash === `#${hash}`
  }

  const statusClass = () => {
    return isOpen ? ` ${styles['active']}` : ``
  }

  useEffect(() => {
    setStatus(opened || false)
  }, [opened])

  useEffect(() => {
    if (isHashMatching()) {
      const element = document.getElementById(hash)
      const titles = document.getElementsByClassName('title')

      for (let i = 0; i < titles.length; i++) {
        // Add the active class to the title of the corresponding answer
        if (titles[i].nextElementSibling?.id === hash) {
          titles[i].classList.add(styles['active'])
        }
      }

      if (element) {
        if (!element.classList.contains(styles['active'])) {
          element.classList.add(styles['active'])
        }
      }
    }
  })

  return (
    <div className={styles['accordion']}>
      <div
        className={
          `
        ${styles['title']}` + statusClass()
        }
        onClick={handleClick}
        data-test-id="title"
      >
        {title}
        <i
          className={`
          ${styles['icon']} 
          ${styles['dropdown']}
          `}
        ></i>
      </div>
      <div
        className={`${styles['content']}` + statusClass()}
        id={hash}
        data-test-id="content"
      >
        {/* 
          Normally dangerouslySetInnerHTML introduces a risk 
          for XSS attacks but these come from our constants 
        */}
        {content && <span dangerouslySetInnerHTML={{ __html: content }} />}
        {children}
      </div>
    </div>
  )
}

export default Accordion
