import React, { useContext, useEffect } from 'react'
import Footer from 'containers/Layout/Footer'
import Header from 'containers/Layout/Header'
import { useLocation } from 'react-router-dom'
import AppContext from 'contexts/AppProvider'

import styles from './styles.module.scss'
import textStyles from 'components/Text/styles.module.scss'

type LayoutProps = {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation()

  const {
    openContactUs,
    openLimitsAndCompliance,
    openFraudPrevention,
    openPrivacyPolicy,
    openTermsOfService,
    openFAQ,
    isMobileView
  } = useContext(AppContext)

  useEffect(() => {
    const { search } = location
    let modal = search.slice(1)

    if (modal.includes('ref='))
      modal = search.replace(/\?ref=\w+/g, '').slice(1)
    modal = modal.replace(/=.+/g, '')

    switch (modal) {
      case 'faq':
        return openFAQ()
      case 'privacypolicy':
        return openPrivacyPolicy()
      case 'termsofservice':
        return openTermsOfService()
      case 'limitscompliance':
        return openLimitsAndCompliance()
      case 'fraudprevention':
        return openFraudPrevention()
      case 'contact':
        return openContactUs()
      default:
        break
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <div className={styles['layout-container']}>
      <Header />
      <main className={styles['main']}>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
