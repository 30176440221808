import { useHref } from 'hooks'

import modalStyles from 'components/Modal/styles.module.scss'
import styles from './styles.module.scss'

type TableContent = {
  [key: string]: {
    daily: string
    weekly: string
    requirement: string
  }
}

const LimitsAndCompliance = () => {
  useHref('limitscompliance')

  let items: TableContent = {
    '1': {
      daily: '500',
      weekly: '500',
      requirement: 'Confirmed phone number'
    },
    '2': {
      daily: '2000',
      weekly: '14000',
      requirement: 'Caller ID / User provided name'
    },
    '3': {
      daily: '2999',
      weekly: '20993',
      requirement: 'Government Issued ID'
    }
  }

  return (
    <>
      <div className={modalStyles['modal-header']}>
        <h1>Limits / Compliance</h1>
      </div>
      <table className={styles['table']}>
        <thead>
          <tr>
            <th>Daily Limit</th>
            <th>Weekly Limit</th>
            <th>Requirements</th>
          </tr>
        </thead>
        <tbody>
          {items &&
            Object.entries(items).map(([key, value]) => {
              return (
                <tr key={key}>
                  <td>${value.daily}</td>
                  <td>${value.weekly}</td>
                  <td>{value.requirement}</td>
                </tr>
              )
            })}
          <tr>
            <td colSpan={3} className={styles['centered']}>
              <strong>
                Requirements are also subject to CardCoins{' '}
                <a href="/?termsofservice">Terms of Service</a>
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        Pursuant to federal anti-money laundering laws, including the U.S. Bank
        Secrecy Act and the USA Patriot Act, CardCoins is required to implement
        an effective, risk based, anti-money laundering and countering funding
        of terrorism compliance program. CardCoins has adopted an Anti-Money
        Laundering Compliance Policy to set forth its commitment to comply with
        the requirements of the BSA and the Patriot Act, U.S. sanctions laws,
        and to protect itself from being used to facilitate money laundering,
        terrorist financing, or other financial crimes.",
      </p>
      <p className={styles['secondary']} data-cy="companyName">
        CardCoins is a registered Money Transmitter with the Financial Crimes
        Enforcement Network.
      </p>
    </>
  )
}

export default LimitsAndCompliance
