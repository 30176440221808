import { useContext, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
// import Logo from 'assets/images/logo.png'
import Logo from 'assets/images/logo.svg'
import AppContext from 'contexts/AppProvider'
import MobileHeader from './mobileHeader/index'

import styles from './styles.module.scss'

const Header = () => {
  const { openFAQ, isMobileView } = useContext(AppContext)
  const [menu, setMenuStatus] = useState(false)

  return (
    <>
      {isMobileView ? (
        <MobileHeader />
      ) : (
        <header className={styles['header']}>
          <div className={styles['container']}>
            <Link className={styles['header-logo-img']} to="/">
              <img src={Logo} alt="Cardcoins" />
            </Link>
            <nav
              className={
                `
              ${styles['header-nav']}` + (menu ? ` ${styles['show']}` : ``)
              }
            >
              <ul>
                <li>
                  <NavLink
                    className={styles['nav-link']}
                    to="/"
                    activeClassName={styles['active']}
                    exact={true}
                    onClick={(event) => setMenuStatus(false)}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <a
                    // eslint-disable-next-line max-len
                    href="https://blog.cardcoins.co/how-to-buy-bitcoin-with-cardcoins"
                    className={styles['nav-link']}
                    target="_blank"
                    rel="noreferrer"
                  >
                    How to
                  </a>
                </li>
                <li>
                  <a
                    className={styles['nav-link']}
                    href="#top"
                    target="_self"
                    onClick={openFAQ}
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <NavLink
                    className={styles['nav-link']}
                    to="/map"
                    activeClassName={styles['active']}
                    onClick={(event) => setMenuStatus(false)}
                  >
                    Locations
                  </NavLink>
                </li>
                <li>
                  <a
                    className={styles['nav-link']}
                    href="https://blog.cardcoins.co/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      )}
    </>
  )
}

export default Header
