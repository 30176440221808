import {
  STEP_IMAGES,
  STEP_ON_HOLD,
  STEP_COMPLETE,
  STEP_PROCESSING
} from 'constants/index'
import fetchOrder from 'utils/fetchOrder'

const refetchStatus = async (
  orderId: string,
  setStep: (step: number) => void
) => {
  const res = await fetchOrder(orderId)

  // Flesh out error handler
  if (res instanceof Error) {
    throw res
  }

  if (
    res.order.attachments &&
    res.order.attachments.length === 0 &&
    res.order.status === 'review'
  ) {
    // Orders can be put on hold as well as taken off of hold

    setStep(STEP_PROCESSING)
  } else if (
    res.order.attachments &&
    res.order.attachments.length > 0 &&
    res.order.status === 'payment'
  ) {
    // One or more images has been rejected, return to previous step
    setStep(STEP_IMAGES)

    // Refresh the page so the form renders the image step correctly
    window.location.reload()
  } else if (res.order.status === 'hold') {
    // Order has been placed on hold
    setStep(STEP_ON_HOLD)
  } else if (
    res.order.attachments &&
    res.order.attachments.length === 0 &&
    res.order.status === 'closed'
  ) {
    setStep(STEP_COMPLETE)
  }

  return
}

export default refetchStatus
