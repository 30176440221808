import React, { useState } from 'react'

import textFieldStyles from 'components/Inputs/TextField/styles.module.scss'
import buttonStyles from 'components/Buttons/styles.module.scss'
import formStyles from 'containers/Form/styles.module.scss'
import layoutStyles from 'containers/Layout/Form/styles.module.scss'

type AuthObject = {
  u: string
  p: string
} | null

type Props = {
  onSave: () => void
}

const AuthForm = ({ onSave }: Props) => {
  let auth: AuthObject = null

  const authString = window.localStorage.getItem('auth')

  if (authString !== null) {
    try {
      auth = JSON.parse(authString) as AuthObject
    } catch (error) {
      console.warn(error)
    }
  }

  if (auth === null) {
    auth = {
      u: '',
      p: ''
    }
  }

  const [username, setUsername] = useState(auth.u)
  const [password, setPassword] = useState(auth.p)

  const save = () => {
    window.localStorage.setItem(
      'auth',
      JSON.stringify({ u: username, p: password })
    )

    onSave()
  }

  return (
    <>
      <form action="#" method="post" onSubmit={() => save()}>
        <div className={layoutStyles['form-row']}>
          <div className={layoutStyles['form-column']}>
            <div className={textFieldStyles['text-field-container']}>
              <label
                className={textFieldStyles['text-field-label']}
                htmlFor="input-phone"
              >
                Username
              </label>
              <input
                className={textFieldStyles['text-field-input']}
                type="text"
                placeholder="Username"
                value={username}
                onChange={(event) => setUsername(event.target.value.trim())}
              />
            </div>
            <div className={textFieldStyles['text-field-container']}>
              <label
                className={textFieldStyles['text-field-laebl']}
                htmlFor="input-phone"
              >
                Password
              </label>
              <input
                className={textFieldStyles['text-field-input']}
                type="text"
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value.trim())}
              />
            </div>
          </div>
        </div>
        <div className={`${formStyles['form-action']}`}>
          <button
            className={`
            ${buttonStyles['btn']}
            ${buttonStyles['btn-primary']}
          `}
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </>
  )
}

export default AuthForm
