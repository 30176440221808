import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

function addContainerUrl(
  history: ReturnType<typeof useHistory>,
  container: string,
  hash?: string,
  queryValue?: string
) {
  const {
    location: { pathname, search },
    push
  } = history

  const regexStr = new RegExp(`[?&]${container}.*`, 'g')
  const banStr = new RegExp(`[?&=]`, 'g')

  const searchString =
    search.includes('?') && search.includes('ref=') ? '&' : '?'
  const queryStringRegex = new RegExp(`[?&]${container}=`, 'g')
  let queryStringValue = queryValue
    ? queryValue
    : search.replace(/\?ref=\w+/g, '').replace(queryStringRegex, '')
  const finalString = `${pathname}${search.replace(
    regexStr,
    ''
  )}${searchString}${container}${
    !!queryStringValue && !banStr.test(queryStringValue)
      ? `=${queryStringValue}`
      : ''
  }${hash ? `#${hash}` : ''}`

  push(finalString)
}

function addInitialContainerUrl(
  history: ReturnType<typeof useHistory>,
  container: string
) {
  const {
    location: { pathname, search, hash },
    push
  } = history
  if (!search) push(`${pathname}?${container}`)
  else addContainerUrl(history, container, hash?.slice(1))
}

function removeContainerUrl(
  history: ReturnType<typeof useHistory>,
  container: string
) {
  const {
    location: { pathname, search },
    push
  } = history
  const regexStr = new RegExp(`[?&]${container}.*`, 'g')
  push(`${pathname}${search.replace(regexStr, '')}`)
}

const useHref = (href: string) => {
  const history = useHistory()

  useEffect(() => {
    addInitialContainerUrl(history, href)

    return () => removeContainerUrl(history, href)
  }, [history, href])
}

export default useHref
