import { ReactComponent as Icon } from 'assets/images/maintenance.svg'

import styles from './styles.module.scss'
import textStyles from 'components/Text/styles.module.scss'
import layoutStyles from 'containers/Layout/styles.module.scss'
import containerStyles from 'containers/styles.module.scss'

type Props = {
  header: string
  message: string
}

const Maintenance = ({ header, message }: Props) => {
  return (
    <div
      className={`
      ${containerStyles['page-container']}
      ${styles['container-wrapper']}
      `}
    >
      <div
        className={`
        ${layoutStyles['form-content']}
        ${styles['content']}
        `}
      >
        <div
          className={`
          ${layoutStyles['window-inner']} 
          ${textStyles['text-center']}
          ${styles['inner-content']}
        `}
        >
          <Icon />
          <h4 className={textStyles['form-header']}>Under Maintenance</h4>
          {header && <p>{header}</p>}
          {message && <p>{message}</p>}
        </div>
      </div>
    </div>
  )
}

export default Maintenance
