import { HeadersInit } from 'node-fetch'
import { authHeader, prepareURL, prepareOptions } from 'utils'

const createOrderIDContactOrder = async (
  orderID: string,
  contact_body: string,
  contact_type: string,
  keys: string[],
  recaptcha_response: string
): Promise<{ message: 'success' }> => {
  let headers: HeadersInit = {}

  let auth = authHeader()

  if (auth !== false) {
    headers.Authorization = auth
  }

  const reqBody = {
    type: contact_type,
    body: contact_body,
    images: keys
  }

  if (recaptcha_response && recaptcha_response.length > 0) {
    // @ts-ignore
    reqBody.recaptcha_response = recaptcha_response
  }

  const res = await fetch(
    prepareURL(`/orders/${orderID}/contact`),
    prepareOptions(reqBody)
  )

  if (!res.ok) throw res

  return await res.json()
}

export default createOrderIDContactOrder
