import { FetchOrderResponse } from 'types'
import { HeadersInit } from 'node-fetch'
import { prepareURL, authHeader } from 'utils'

const fetchOrder = async (
  orderID: string,
  abortController: AbortController = new AbortController()
): Promise<FetchOrderResponse | Error> => {
  let headers: HeadersInit = {}
  let auth = authHeader()

  if (auth !== false) {
    headers.Authorization = auth
  }

  const options = {
    signal: abortController.signal,
    headers,
    method: 'GET'
  }

  const res = await fetch(prepareURL(`/orders/${orderID}`), options)

  if (400 <= res.status && res.status <= 500) {
    const { message } = await res.json()
    // if (res.status === 404) {
    if (message === 'Failed to load order.') {
      let error = new Error('Order not found')
      error.cause = 404
      return error
    }

    let error = new Error(message)
    error.cause = res.status

    return error
  }

  const { data } = await res.json()

  return data
}

export default fetchOrder
