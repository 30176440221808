import React, { useEffect, createContext, useState, ReactNode } from 'react'
import Modal from 'components/Modal'
import AuthForm from 'components/AuthForm'
import {
  ContactUs,
  LimitsAndCompliance,
  FraudPrevention,
  PrivacyPolicy,
  TermsOfService,
  FAQ
} from 'pages'

interface AppContextType {
  closeModal: () => void
  setModal: (title: string, content: ReactNode) => void
  openContactUs: (event?: React.MouseEvent<HTMLAnchorElement>) => void
  openLimitsAndCompliance: (event?: React.MouseEvent<HTMLAnchorElement>) => void
  openFraudPrevention: (event?: React.MouseEvent<HTMLAnchorElement>) => void
  openPrivacyPolicy: (event?: React.MouseEvent<HTMLAnchorElement>) => void
  openTermsOfService: (event?: React.MouseEvent<HTMLAnchorElement>) => void
  openFAQ: (event?: React.MouseEvent<HTMLAnchorElement>) => void
  openAuth: (event?: React.MouseEvent<HTMLAnchorElement>) => void
  isMobileView: boolean
}

const AppContext = createContext<AppContextType>({
  closeModal: () => {},
  setModal: () => {},
  openContactUs: () => {},
  openLimitsAndCompliance: () => {},
  openFraudPrevention: () => {},
  openPrivacyPolicy: () => {},
  openTermsOfService: () => {},
  openFAQ: () => {},
  openAuth: () => {},
  isMobileView: false
})

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modalStatus, setModalStatus] = useState<boolean>(false)
  const [content, setContent] = useState<ReactNode>(false)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const onModalClose = (event?: React.MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault()
    setModalStatus(false)
  }

  const onOpen = (
    event: React.MouseEvent<HTMLAnchorElement> | undefined,
    content: ReactNode
  ) => {
    if (event) {
      event.preventDefault()
    }
    setContent(content)
    setModalStatus(true)
  }

  return (
    <AppContext.Provider
      value={{
        closeModal: () => onModalClose(),
        setModal: (title: string, content: ReactNode) => {
          setContent(content)
          setModalStatus(true)
        },

        openContactUs: (event?: React.MouseEvent<HTMLAnchorElement>) => {
          onOpen(event, <ContactUs />)
        },
        openLimitsAndCompliance: (
          event?: React.MouseEvent<HTMLAnchorElement>
        ) => {
          onOpen(event, <LimitsAndCompliance />)
        },
        openFraudPrevention: (event?: React.MouseEvent<HTMLAnchorElement>) => {
          onOpen(event, <FraudPrevention />)
        },
        openPrivacyPolicy: (event?: React.MouseEvent<HTMLAnchorElement>) => {
          onOpen(event, <PrivacyPolicy />)
        },
        openTermsOfService: (event?: React.MouseEvent<HTMLAnchorElement>) => {
          onOpen(event, <TermsOfService />)
        },
        openFAQ: (event?: React.MouseEvent<HTMLAnchorElement>) => {
          onOpen(event, <FAQ />)
        },
        openAuth: (event?: React.MouseEvent<HTMLAnchorElement>) => {
          onOpen(event, <AuthForm onSave={onModalClose} />)
        },
        isMobileView: isMobile
      }}
    >
      {children}
      <Modal show={modalStatus} handleClose={onModalClose}>
        {content && content}
      </Modal>
    </AppContext.Provider>
  )
}

export default AppContext

export { AppProvider, AppContext }
